// External
import React, { useState } from "react";

// Internal
import { ContactRoutes } from "routes";
import styles from "./Contacts.module.scss";
import { TabBar } from "components/layouts";
import { useNavigate } from "react-router-dom";
const menuItems = [
  {
    id: 0,
    name: "All Contacts",
    to: "/contacts",
    icon: `people`,
  },
  {
    id: 1,
    name: "Audiences",
    to: "/contacts/audiences",
    icon: `person-video3`,
  },
];

export const Contacts = ({ refetchTasks }) => {
  const navigate = useNavigate();
  const [active, setActive] = useState(handlepage());
  function handlepage() {
    let page = localStorage.getItem("contactspage");
    if (page === "1") {
      return 1;
    } else if (page === "0") {
      return 0;
    }
    return 0;
  }

  function updateActive(id) {
    localStorage.setItem("contactspage", id);
    setActive(id);
  }

  return (
    <div className={styles.page}>
      <div className={styles.tabs}>
        <TabBar
          tabBarItems={menuItems}
          active={active}
          updateActive={updateActive}
        ></TabBar>
      </div>
      <ContactRoutes refetchTasks={refetchTasks} />
    </div>
  );
};
