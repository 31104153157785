import styles from "../SettingsAccordion.module.scss";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import {
  DoughnutType,
  PieType,
  TableType,
  VertBarType,
} from "../../QuestionChart";
import {
  TextFieldSimple as TextField,
  TextFieldSimple,
} from "components/inputs";
import { Label } from "components/layouts/Label/Label";
import { useGetEmailsInSurveys } from "api/resources/projects/distributions";
import { StatComparison } from "./SurveyData/StatComparison";
import FlexRow from "components/layouts/FlexRow/FlexRow";

// I split these two components up because then the option for Pivot By can still appear in the settings, because
// if there have been no filters created for this chart yet, the Pivots component will break. Filter Settings creates
// and updates the filters, so PivotSettings waits for a moment for Filter Settings to create it, then Pivots will be
// able to be seen. Again, this is just a safeguard against the condition of no filters having been created yet. If the
// user clicks on Pivot By without any filters built in time, nothing will drop down, giving the appearance there are none.

export function AdvancedStatSettings({
  viz,
  updateViz,
  changeSettingsField,
  projects,
  visible,
  setVisible,
}) {
  return (
    <div
      key={viz.id}
      className={`${styles.header} ${visible ? styles.headervisible : ""}`}
      onClick={!visible ? setVisible : undefined}
    >
      <FlexRow
        onClick={visible ? setVisible : undefined}
        style={{ cursor: "pointer" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1em",
          }}
        >
          Advanced Settings
          <i
            style={{ fontSize: "1.2em" }}
            className="bi bi-gear-wide-connected"
          ></i>
        </div>

        <span className={styles.accordionicon}>
          <i className="bi bi-caret-left-fill"></i>
        </span>
      </FlexRow>
      {visible && (
        <Settings
          viz={viz}
          updateViz={updateViz}
          projects={projects}
          changeSettingsField={changeSettingsField}
        />
      )}
    </div>
  );
}

function Settings({ viz, updateViz, changeSettingsField, projects }) {
  function toggleByPercentOfParticipants_StatChart(val) {
    let newViz = { ...viz };
    newViz.designSettings.byPercent = val;
    newViz.designSettings.byPercentOfReceived = false;
    newViz.designSettings.graphMax = null;
    newViz.designSettings.graphMin = null;
    updateViz(newViz);
  }

  function toggleByPercentOfReceived_StatChart(val) {
    let newViz = { ...viz };
    newViz.designSettings.byPercent = val;
    newViz.designSettings.byPercentOfReceived = val;
    newViz.designSettings.graphMax = null;
    newViz.designSettings.graphMin = null;
    updateViz(newViz);
  }

  function toggleComparison(val) {
    let copy = JSON.parse(JSON.stringify(viz)); // So it doesn't render comparison.jsx before getComparisonQs could be finished
    copy.designSettings.useComparison = val;
    if (val) {
      copy.designSettings.hasLegend = true;
      if (copy.type === DoughnutType || copy.type === PieType) {
        copy.type = VertBarType;
        copy.designSettings.legendPosition = "top";
        copy.designSettings.dataLabelValue.position = "end";
        copy.designSettings.dataLabelValue.alignment = "end";
      }
      if (!copy.comparison) {
        copy.comparison = {
          projectIds: [],
          dynamics: [],
        };
      }
    } else {
      copy.designSettings.hasLegend =
        copy.designSettings.split || copy.pivotString;
    }

    updateViz(copy);
  }

  function toggleUndefined(val) {
    let newViz = { ...viz };
    newViz.designSettings.showUndefined = val;

    if (newViz.type === TableType) {
      if (!val && viz.designSettings.split) {
        if ("Undefined" in viz.designSettings.tablePivotFields) {
          delete viz.designSettings.tablePivotFields.Undefined;
          let ind = viz.designSettings.tablePivotOrder.indexOf("Undefined");
          viz.designSettings.tablePivotOrder.splice(ind, 1);
        }
      }
    }

    updateViz(newViz);
  }

  function undefinedLabelChange(val) {
    if (
      viz.type === TableType &&
      "Undefined" in viz.designSettings.tablePivotFields
    ) {
      viz.designSettings.tablePivotFields.Undefined.name = val;
    }
    changeSettingsField("undefinedLabel", val);
  }

  function toggleEmpty(val) {
    if (
      viz.type === TableType &&
      val &&
      viz.pivotString &&
      viz.designSettings.split &&
      !viz.designSettings.split.includes("survey") &&
      viz.designSettings.split !== "nps" &&
      viz.designSettings.split !== "question"
    ) {
      let newViz = { ...viz };

      let columns = { ...newViz.designSettings.tablePivotFields };
      let order = [...newViz.designSettings.tablePivotOrder];

      let fields = custom_fields.find(
        (f) => f.name === viz.designSettings.split
      );
      if (fields?.properties) {
        let props = fields.properties;

        for (let i = 0; i < props.length; i++) {
          if (props[i] && !(props[i] in columns)) {
            let label = props[i];
            columns[label] = {
              name: label,
              show: true,
            };
            order.splice(i + 1, 0, label);
          }
        }

        newViz.designSettings.tablePivotFields = columns;
        newViz.designSettings.tablePivotOrder = order;
        newViz.designSettings.showNonParticipating = val;
        updateViz(newViz);
      }
    } else {
      changeSettingsField("showNonParticipating", val);
    }
  }

  const pivotOrSplit = viz.pivotString || viz.designSettings.split;

  const byEmail =
    (viz.pivotString === "email" || viz.designSettings.split === "email") &&
    !(viz.pivotString && viz.designSettings.split);

  return (
    <>
      <div
        className={styles.body}
        style={{
          width: "100%",
          maxWidth: "300px",
          height: "100%",
          backgroundColor: "transparent",
          padding: "0px 0px 0px 1.5em",
          margin: "0px",
          gap: "1em",
        }}
      >
        <div className={`${styles.headerStatic}`}>
          Advanced
          <i style={{ fontSize: "1.2em" }} className="bi bi-calculator"></i>
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div className={styles.setting2} style={{ gap: "0em" }}>
            <ToggleSwitch
              startChecked={
                viz.designSettings.byPercent &&
                (pivotOrSplit ? true : !viz.designSettings.byPercentOfReceived)
              }
              handleCheck={toggleByPercentOfParticipants_StatChart}
            ></ToggleSwitch>
            <Label
              style={{
                fontWeight: "400",
                width: "fit-content",
                fontSize: ".8em",
              }}
            >
              {pivotOrSplit ? "By percent" : "By percent of audience"}
            </Label>
          </div>

          {!pivotOrSplit && (
            <div className={styles.setting2} style={{ gap: "0em" }}>
              <ToggleSwitch
                startChecked={viz.designSettings.byPercentOfReceived}
                handleCheck={toggleByPercentOfReceived_StatChart}
              ></ToggleSwitch>
              <Label
                style={{
                  fontWeight: "400",
                  width: "fit-content",
                  fontSize: ".8em",
                }}
              >
                By percent of those who received an invite{" "}
                {viz.designSettings.showBars.find(
                  (b) => b.value === "bounced" || b.value === "blocked"
                ) && (
                  <span style={{ fontSize: ".9em", color: "#738c91" }}>
                    {"(besides blocked and bounced)"}
                  </span>
                )}
              </Label>
            </div>
          )}

          {(viz.designSettings.split || viz.pivotString) && !byEmail && (
            <>
              <div className={styles.setting2} style={{ gap: "5px" }}>
                <ToggleSwitch
                  startChecked={viz.designSettings?.showUndefined}
                  handleCheck={toggleUndefined}
                ></ToggleSwitch>
                <Label
                  style={{
                    fontWeight: "400",
                    width: "fit-content",
                    fontSize: ".8em",
                  }}
                >
                  Show Undefined
                </Label>

                {viz.designSettings?.showUndefined && (
                  <div
                    className={styles.settingWithLabel}
                    style={{
                      padding: "0em",
                      margin: "0em",
                      marginLeft: "10px",
                    }}
                  >
                    <div
                      style={{
                        paddingLeft: "0px",
                        width: "100px",
                        display: "flex",
                      }}
                    >
                      <TextField
                        value={
                          viz.designSettings.undefinedLabel
                            ? viz.designSettings.undefinedLabel
                            : ""
                        }
                        placeholder="Undefined"
                        onSave={undefinedLabelChange}
                        onEnter={undefinedLabelChange}
                        style={{ height: "25px" }}
                      ></TextField>
                      <Label
                        style={{
                          fontWeight: "400",
                          width: "fit-content",
                          fontSize: ".8em",
                          paddingLeft: "0px",
                        }}
                        tooltipText="What to display when a participant doesn't have a particular contact field"
                        labelIcon={<i className="bi-info-circle"></i>}
                        tooltipStyle={{
                          right: 0,
                        }}
                      ></Label>
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.setting2} style={{ gap: "0em" }}>
                <ToggleSwitch
                  startChecked={viz.designSettings?.showNonParticipating}
                  handleCheck={toggleEmpty}
                ></ToggleSwitch>
                <Label
                  style={{
                    fontWeight: "400",
                    width: "fit-content",
                    fontSize: ".8em",
                  }}
                >
                  Include Empty Fields
                </Label>
              </div>{" "}
            </>
          )}

          <OnlyLinks
            viz={viz}
            changeSettingsField={changeSettingsField}
            projects={projects}
          />
        </div>

        {viz.type !== TableType && (
          <>
            <div
              className={`${styles.headerStatic}`}
              style={{ marginTop: "1em" }}
            >
              Comparison
              <i
                style={{ fontSize: "1.2em" }}
                className="bi bi-graph-up-arrow"
              ></i>
            </div>
            <div
              className={styles.setting2}
              style={{ gap: "0em", paddingBottom: "10px" }}
            >
              <ToggleSwitch
                startChecked={viz.designSettings.useComparison}
                handleCheck={toggleComparison}
              ></ToggleSwitch>
              <Label
                style={{
                  fontWeight: "400",
                  width: "fit-content",
                  fontSize: ".8em",
                }}
                tooltipText={
                  "Runs the average count or calculation over compared data."
                }
                tooltipStyle={{
                  right: 0,
                }}
                labelIcon={<i className="bi bi-info-circle"></i>}
              >
                Run Comparison
              </Label>
            </div>

            {viz.designSettings.useComparison && projects.length > 0 && (
              <>
                {(viz.comparison.dynamics.length > 0 ||
                  viz.comparison.projectIds.length > 0) && (
                  <>
                    {!viz.pivotString && !viz.designSettings.split && (
                      <TextFieldSimple
                        value={
                          viz.designSettings.chosenLabel
                            ? viz.designSettings.chosenLabel
                            : ""
                        }
                        placeholder="Chosen"
                        onSave={(val) =>
                          changeSettingsField("chosenLabel", val)
                        }
                        onEnter={(val) =>
                          changeSettingsField("chosenLabel", val)
                        }
                        label={"Chosen Label"}
                        style={{ height: "30px" }}
                      ></TextFieldSimple>
                    )}
                    <TextFieldSimple
                      value={
                        viz.designSettings.comparisonLabel
                          ? viz.designSettings.comparisonLabel
                          : ""
                      }
                      placeholder="Comparison"
                      onSave={(val) =>
                        changeSettingsField("comparisonLabel", val)
                      }
                      onEnter={(val) =>
                        changeSettingsField("comparisonLabel", val)
                      }
                      label={"Comparison Label"}
                      style={{ height: "30px" }}
                    ></TextFieldSimple>
                  </>
                )}

                <StatComparison viz={viz} updateViz={updateViz} />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

function OnlyLinks({ viz, changeSettingsField, projects }) {
  const getEmails = useGetEmailsInSurveys(projects.map((p) => p.id));

  return (
    <>
      {getEmails.isSuccess && getEmails.data.emails.some((d) => !d.hasLink) && (
        <div className={styles.setting2} style={{ gap: "0em" }}>
          <ToggleSwitch
            startChecked={viz.designSettings?.onlyLinks}
            handleCheck={(val) => changeSettingsField("onlyLinks", val)}
          ></ToggleSwitch>
          <Label
            style={{
              fontWeight: "400",
              width: "fit-content",
              fontSize: ".8em",
            }}
          >
            Only emails with survey links
          </Label>
        </div>
      )}
    </>
  );
}
