import { useEffect, useRef, useState } from "react";

import styles from "./QuestionSettings.module.scss";
import styles2 from "../SurveyDesign/SurveyDesign.module.scss";
import Button from "components/Button/Button";
import { Label } from "components/layouts/Label/Label";
import { ChoiceSettings } from "./Choice/ChoiceSettings";
import { TextSettings } from "./Text/TextSettings";
import { ScaleSettings } from "./Scale/ScaleSettings";
import { MatrixSettings } from "./Matrix/MatrixSettings";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import EditTags from "../EditTags/EditTags";
import Buckets from "../Buckets/Buckets";
import Icon from "components/Icon/Icon";

export const questionTypes = [
    {
        label: (
            <FlexRow start>
                <i className="bi bi-list-ul"></i> Multiple Choice
            </FlexRow>
        ),
        value: "MultipleChoice",
    },

    {
        label: (
            <FlexRow start>
                <i className="bi bi-pencil"></i> Free Response
            </FlexRow>
        ),
        value: "Text",
    },

    {
        label: (
            <FlexRow start>
                <i className="bi bi-123"></i> Number Scale
            </FlexRow>
        ),
        value: "NumberScale",
    },
    {
        label: (
            <FlexRow start>
                <i className="bi bi-list-ol"></i> Ranking
            </FlexRow>
        ),
        value: "Ranking",
    },
    {
        label: (
            <FlexRow start>
                <i className="bi bi-sliders"></i> Number Slider
            </FlexRow>
        ),
        value: "NumberSlider",
    },
    {
        label: (
            <FlexRow start>
                <i className="bi bi-bar-chart-steps"></i> Matrix
            </FlexRow>
        ),
        value: "Matrix",
    },
];

export const QuestionSettings = ({
    question,
    saveQuestion,
    onCancel,
    dependents,
    setUpLogicResolver,
    onRetract,
    allQuestions,
    showLogic,
    setShowLogic,
    showTags,
    setShowTags,
    setShowTriggers,
}) => {
    const sectionTypes = {
        FormRegistration: {
            label: "Form Registration",
            value: "Form Registration",
            name: "Form Registration",
            icon: <i className="bi bi-list-check"></i>,
        },
        Description: {
            label: "Description",
            value: "Description",
            name: "Description",
            icon: <i className="bi bi-chat-left-text"></i>,
        },
    };

    function handleChangeType(type) {
        if (question.type !== type) {
            if (dependents) {
                setUpLogicResolver(
                    "change the type of question on",
                    "edit",
                    question,
                    () => finishChangingType(type)
                );
            } else {
                finishChangingType(type);
            }
        } else if (
            question.choiceQuestion &&
            type === "MultipleChoice" &&
            question.choiceQuestion.isRanking
        ) {
            // won't affect dependents
            finishChangingType(type);
        }
    }

    function finishChangingType(type) {
        let oldType = question.type;
        question.type = type;
        if (type === "MultipleChoice") {
            question.choiceQuestion = {
                isMultiSelect: false,
                isRandomized: false,
                hasOtherOption: false,
                isRanking: false,
                otherOptionLabel: "Other",
                limit: null,
                choices: question.choiceQuestion?.choices
                    ? question.choiceQuestion.choices
                    : [],
            };
            question.scaleQuestion = null;
            question.textQuestion = null;
            question.matrixQuestion = null;
        } else if (type === "NumberScale" || type === "NumberSlider") {
            question.scaleQuestion = {
                min: 1,
                minDescription: "",
                max: 5,
                maxDescription: "",
                step: 1,
            };
            question.choiceQuestion = null;
            question.textQuestion = null;
        } else if (type === "Text") {
            question.textQuestion = {
                placeholder: "Enter Text",
                maxLength: 1000,
                autoBucket: true,
                bucket: [],
            };
            question.choiceQuestion = null;
            question.scaleQuestion = null;
            question.matrixQuestion = null;
        } else if (type === "Ranking") {
            question.choiceQuestion = {
                isMultiSelect: false,
                isRandomized: false,
                isRanking: true,
                hasOtherOption: false,
                otherOptionLabel: "Other",
                limit: null,
                choices: question.choiceQuestion?.choices
                    ? question.choiceQuestion.choices
                    : [],
            };
            question.type = "MultipleChoice";
            question.scaleQuestion = null;
            question.textQuestion = null;
            question.matrixQuestion = null;
        } else if (type === "Matrix") {
            question.matrixQuestion = {
                options: [],
                settings: {},
                type: oldType,
            };
        }
        question.isScore = false;
        question.isComment = false;
        question.questionTag = [];

        saveQuestion(question);
        onRetract(question.id);
    }

    const currentType =
        question.choiceQuestion &&
        question.choiceQuestion?.isRanking &&
        !question.matrixQuestion
            ? questionTypes.find((t) => t.value === "Ranking")
            : questionTypes.find((t) => t.value === question.type);

    const [groups, setGroups] = useState();

    const [triggers, setTriggers] = useState();

    useEffect(() => {
        if (question?.conditionGroups) {
            setGroups(JSON.parse(JSON.stringify(question?.conditionGroups)));
        } else {
            setGroups([]);
        }

        if (question?.triggers) {
            setTriggers(JSON.parse(JSON.stringify(question?.triggers)));
        } else {
            setTriggers([]);
        }
    }, [question]);

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleAccordion = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className={styles2.body} style={{ paddingBottom: "20rem" }}>
            {/* <div
        className={styles.section_label6}
        style={{
          margin: "0",
          width: "fit-content",
          color: "#15bcc7",
          padding: " 0rem 1rem",
        }}
      >
        Question Settings
        <HorizontalBar
          height={2}
          style={{ marginTop: ".1rem" }}
          backgroundColor={"#15bcc7"}
        ></HorizontalBar>
      </div> */}
            <div
                className={styles.label5}
                style={{
                    margin: "0",
                    width: "fit-content",
                    padding: " 0rem 1rem",
                    fontWeight: "500",
                }}
            >
                Question Settings
            </div>
            <div className={styles2.settingVertical}>
                <CombinedInput
                    label="Question Type"
                    value={currentType}
                    options={questionTypes}
                    select
                    onChange={(t) => handleChangeType(t?.value)}
                    icon={"bi-chevron-down"}
                />
            </div>
            {question.type === "MultipleChoice" && (
                <div className={styles2.settingVertical}>
                    <ChoiceSettings
                        question={question}
                        saveQuestion={saveQuestion}
                    />{" "}
                </div>
            )}
            {question.type === "Text" && (
                <div className={styles2.settingVertical}>
                    <TextSettings
                        question={question}
                        saveQuestion={saveQuestion}
                        dependents={dependents}
                        setUpLogicResolver={setUpLogicResolver}
                    />
                </div>
            )}
            {(question.type === "NumberScale" ||
                question.type === "NumberSlider") && (
                <div className={styles2.settingVertical}>
                    <ScaleSettings
                        question={question}
                        saveQuestion={saveQuestion}
                        dependents={dependents}
                        setUpLogicResolver={setUpLogicResolver}
                    />
                </div>
            )}
            {question.type === "Matrix" && (
                <div className={styles2.settingVertical}>
                    <MatrixSettings
                        question={question}
                        saveQuestion={saveQuestion}
                        dependents={dependents}
                        setUpLogicResolver={setUpLogicResolver}
                        onRetract={onRetract}
                    />
                </div>
            )}
            {"questionText" in question && (
                <EditTags
                    question={question}
                    saveQuestion={saveQuestion}
                    close={() => setShowTags(false)}
                    allQuestions={allQuestions}
                    display
                    setAddNew={setShowTags}
                    addNew={showTags}
                />
            )}
            {/* Question Logic */}
            <div className={styles.body}>
                <FlexRow start gap={0}>
                    <Icon iconName={"signpost-fill"} seafoam></Icon>
                    <div
                        className={styles.label5}
                        style={{ margin: "0", fontWeight: "500" }}
                    >
                        Question Logic
                    </div>
                </FlexRow>
                {groups && groups.length > 0 && (
                    <FlexRow start gap={0}>
                        <Icon iconName={"check-circle"} green></Icon>
                        <div
                            className={styles.section_label6}
                            style={{ margin: "0", fontWeight: "500" }}
                        >
                            Has Logic
                        </div>
                    </FlexRow>
                )}
                <FlexRow style={{ justifyContent: "center" }} gap={0}>
                    <Button shadow onClick={() => setShowLogic(true)}>
                        Edit Logic
                    </Button>
                </FlexRow>
            </div>

            {/* Question Alerts */}
            <div className={styles.body}>
                <FlexRow start gap={0}>
                    <Icon iconName={"bell-fill"} seafoam></Icon>
                    <div
                        className={styles.label5}
                        style={{ margin: "0", fontWeight: "500" }}
                    >
                        Answer Alerts
                    </div>
                </FlexRow>
                {triggers && triggers.length > 0 && (
                    <FlexRow start gap={0}>
                        <Icon iconName={"check-circle"} green></Icon>
                        <div
                            className={styles.section_label6}
                            style={{ margin: "0", fontWeight: "500" }}
                        >
                            Has Alerts
                        </div>
                    </FlexRow>
                )}
                <FlexRow style={{ justifyContent: "center" }} gap={0}>
                    <Button shadow onClick={() => setShowTriggers(true)}>
                        Edit Alerts
                    </Button>
                </FlexRow>
            </div>
            {"questionText" in question && question?.textQuestion && (
                <Buckets question={question} close={undefined} status={""} />
            )}
            {question?.new && (
                <div
                    style={{
                        bottom: "0",
                        right: "0",
                        display: "flex",
                        paddingTop: "1em",
                        justifyContent: "space-between",
                        flexDirection: "row-reverse",
                        gap: "1em",
                        alignItems: "end",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "1em",
                        }}
                    >
                        <Button
                            red
                            height="35px"
                            shadow
                            onClick={() => onCancel(question)}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export function ChangeQuestionType({ types, current, onSelect, label }) {
    const [show, setShow] = useState(false);

    const ref = useRef(null);

    return (
        <div className={styles.questionTypesContainer}>
            <Label
                style={{
                    fontWeight: "600",
                    fontSize: ".85em",
                    paddingLeft: "1em",
                    paddingBottom: "3px",
                    textTransform: "none",
                }}
            >
                {label}
            </Label>
            <div
                className={`${styles.questionType} ${
                    show ? styles.active : ""
                }`}
                onClick={() => setShow(!show)}
            >
                <div className={styles.type} ref={ref}>
                    {current.label}
                    {current.icon}
                </div>

                <div className={styles.select}>
                    <i className="bi bi-chevron-down"></i>
                </div>
            </div>

            {show && (
                <ChangeTypeMenu
                    types={types}
                    onSelect={(val) => {
                        onSelect(val);
                        setShow(false);
                    }}
                    current={current}
                    close={() => setShow(false)}
                    topRef={ref}
                />
            )}
        </div>
    );
}

function ChangeTypeMenu({ types, current, onSelect, close, topRef }) {
    function onClickOut(e) {
        if (
            ref.current &&
            !ref.current.contains(e.target) &&
            topRef.current &&
            !topRef.current.contains(e.target)
        ) {
            close();
            document.removeEventListener("click", onClickOut, true);
            e.stopPropagation();
        }
    }

    useEffect(() => {
        setTimeout(
            () => document.addEventListener("click", onClickOut, true),
            250
        );
        return () => document.removeEventListener("click", onClickOut, true);
    }, []);

    const ref = useRef();

    return (
        <div className={styles.optionsAnchor}>
            <div className={styles.options} ref={ref}>
                {types.map((type) => (
                    <>
                        {type.value !== current.value && (
                            <div
                                className={styles.option}
                                onClick={() => onSelect(type.value)}
                            >
                                {type.label}
                                {type.icon}
                            </div>
                        )}
                    </>
                ))}
            </div>
        </div>
    );
}
