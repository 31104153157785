import React, { useState } from "react";
import styles from "./Pagination.module.scss";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";

/**
 * A Table that is used to display data
 * @param {type} initHeaders something
 * @param {type} createTitle something
 * @param {type} createMethod something
 * @param {type} data something
 * @param {type} onRowClick something
 * @param {type} setPageNumber something
 * @param {type} pageNumber something
 * @param {type} bottomLeft something
 * @param {type} bottomRight something
 * @param {boolean} noFilter no filter button
 * @param {boolean} noSearch no search bar
 * @param {type} maxPage something
 * @returns {React.ReactElement} a Table component
 */

export function Pagination({
  pageNum,
  setPageNum,
  maxPage,
  length,
  searchString,
  perPage,
  setPerPage,
  setPageSkip,
  maxItems,
}) {
  const [page, setPage] = useState(
    window.localStorage.getItem("page1")
      ? window.localStorage.getItem("page1")
      : pageNum
  );
  const [page2, setPage2] = useState(
    window.localStorage.getItem("page2")
      ? window.localStorage.getItem("page2")
      : pageNum + 1 > maxPage
      ? null
      : pageNum + 1
  );
  const [page3, setPage3] = useState(
    window.localStorage.getItem("page3")
      ? window.localStorage.getItem("page3")
      : pageNum + 2 > maxPage
      ? null
      : pageNum + 2
  );
  const [preSearchPage, setPreSearchPage] = useState(pageNum);

  function setUpPages(newPageNum) {
    let newPage2 = newPageNum + 1 <= maxPage ? newPageNum + 1 : null;
    let newPage3 = newPageNum + 2 <= maxPage ? newPageNum + 2 : null;
    window.localStorage.setItem("page1", newPageNum);
    window.localStorage.setItem("page2", newPage2);
    window.localStorage.setItem("page3", newPage3);
    setPage(newPageNum);
    setPage2(newPage2);
    setPage3(newPage3);
    setPageNum(newPageNum);
  }

  function onOptionChangeHandler(value) {
    let amount = parseInt(value);
    if (amount != perPage) {
      if (amount < perPage) {
        // shrinking, keep pageNum the same
        let newPageSkip = (pageNum - 1) * amount;
        setPageSkip(newPageSkip);
      } else {
        // growing, may need to decrease the page num
        // let newNumPages =
        //   totalCount / amount < 1 ? 1 : Math.ceil(totalCount / amount);
        if (pageNum > 1) {
          let spot = (pageNum - 1) * perPage;
          let newPageNum = Math.floor(spot / amount) + 1;
          let newPageSkip = (newPageNum - 1) * amount;
          setPageSkip(newPageSkip);
          setUpPages(newPageNum);
        }
      }
      setPerPage(amount);
    }
  }

  function nextPage() {
    let oldpage = typeof page === "string" ? parseInt(page) : page;
    let oldpage2 = typeof page2 === "string" ? parseInt(page2) : page2;
    let oldpage3 = typeof page3 === "string" ? parseInt(page3) : page3;
    let newpage = typeof page === "string" ? parseInt(page) : page;
    let newpage2 = typeof page2 === "string" ? parseInt(page2) : page2;
    let newpage3 = typeof page3 === "string" ? parseInt(page3) : page3;
    let thispage = typeof pageNum === "string" ? parseInt(pageNum) : pageNum;
    if (searchString === "" && preSearchPage !== maxPage) {
      setPreSearchPage(preSearchPage);
    }

    if (length < perPage) {
      if (thispage === oldpage) {
        newpage2 = "";
        newpage3 = "";
        setPage2(null);
        setPage3(null);
      } else if (thispage === oldpage2) {
        newpage3 = "";
        setPage3(null);
      }
      if (newpage) window.localStorage.setItem("page1", newpage);
      if (newpage2) window.localStorage.setItem("page2", newpage2);
      if (newpage3) window.localStorage.setItem("page3", newpage3);
    } else {
      if (thispage === maxPage) {
      } else {
        if (thispage === oldpage3) {
          setPage(oldpage3 + 1);
          setPage2(oldpage3 + 2 > maxPage ? null : oldpage3 + 2);
          setPage3(oldpage3 + 3 > maxPage ? null : oldpage3 + 3);
          newpage = oldpage3 + 1;
          newpage2 = oldpage3 + 2 > maxPage ? 0 : oldpage3 + 2;
          newpage3 = oldpage3 + 3 > maxPage ? 0 : oldpage3 + 3;
        }

        pageChange(thispage + 1);
        window.localStorage.setItem("page", thispage + 1);
      }
      if (newpage || newpage === 0)
        window.localStorage.setItem("page1", newpage);
      if (newpage2 || newpage2 === 0)
        window.localStorage.setItem("page2", newpage2);
      if (newpage3 || newpage3 === 0)
        window.localStorage.setItem("page3", newpage3);
    }
  }

  function previousPage(pageNum) {
    let oldpage = typeof page === "string" ? parseInt(page) : page;
    let newpage = typeof page === "string" ? parseInt(page) : page;
    let newpage2 = typeof page2 === "string" ? parseInt(page2) : page2;
    let newpage3 = typeof page3 === "string" ? parseInt(page3) : page3;
    let thispage = typeof pageNum === "string" ? parseInt(pageNum) : pageNum;
    if (searchString === "" && preSearchPage !== 1) {
      setPreSearchPage(preSearchPage - 1);
    }
    if (thispage === 1) {
      //do nothing
    } else {
      if (pageNum === thispage) {
        if (thispage === oldpage) {
          setPage(oldpage - 3);
          setPage2(oldpage - 2 > maxPage ? null : oldpage - 2);
          setPage3(oldpage - 1 > maxPage ? null : oldpage - 1);
          newpage2 = oldpage - 2 > maxPage ? null : oldpage - 2;
          newpage3 = oldpage - 1 > maxPage ? null : oldpage - 1;
          newpage = oldpage - 3;
        }
        // setPageNumber(currPage - 1);
        pageChange(thispage - 1);
        window.localStorage.setItem("page", thispage - 1);
      }
    }
    if (newpage) window.localStorage.setItem("page1", newpage);
    if (newpage2) window.localStorage.setItem("page2", newpage2);
    if (newpage3) window.localStorage.setItem("page3", newpage3);
  }

  function onPageClick(num) {
    if (num !== page) {
      pageChange(num);
    }
  }

  function pageChange(num) {
    let skipAmount = (num - 1) * perPage;
    setPageSkip(skipAmount);
    setUpPages(num);
  }

  const options = [
    { value: 3, label: "3 Per Page" },
    { value: 5, label: "5 Per Page" },
    { value: 8, label: "8 Per Page" },
    { value: 10, label: "10 Per Page" },
    { value: 12, label: "12 Per Page" },
    { value: 15, label: "15 Per Page" },
    { value: 20, label: "20 Per Page" },
    { value: 30, label: "30 Per Page" },
    { value: 40, label: "40 Per Page" },
  ];

  return (
    <div className={styles.pages}>
      {maxItems && <div className={styles.item}>Total: {maxItems}</div>}

      <CombinedInput
        shadow
        options={options}
        value={options.find((i) => i.value === perPage)}
        onChange={(c) => onOptionChangeHandler(c?.value)}
        select
        icon={"bi-chevron-down"}
        style={{
          backgroundColor: "white",
          fontSize: ".8rem",
          width: "fit-content",
        }}
        dropUp
      ></CombinedInput>

      <button
        className={styles.nextButton}
        onClick={() => previousPage(pageNum)}
      >
        <i className="bi bi-chevron-left"></i>
      </button>
      {pageNum > 1 && (
        <>
          <button
            className={`${styles.pageButton} `}
            onClick={() => onPageClick(1)}
          >
            1
          </button>
          {pageNum > 2 && <div>...</div>}
        </>
      )}

      <button
        className={`${styles.pageButton} ${styles.currPage}`}
        // onClick={() => onPageClick(page)}
      >
        {pageNum}
      </button>

      {pageNum < maxPage && (
        <>
          {pageNum < maxPage - 1 && <div>...</div>}
          <button
            className={`${styles.pageButton} ${
              `${pageNum}` === `${maxPage}` ? styles.currPage : ""
            }`}
            onClick={() => onPageClick(maxPage)}
          >
            {maxPage}
          </button>
        </>
      )}

      {/* {pageNum - 1 > 0 && (
        <button
          className={`${styles.pageButton} `}
          onClick={() => onPageClick(page)}
        >
          {page}
        </button>
      )} */}

      {/* {page && page != 0 && (
        <button
          className={`${styles.pageButton} ${
            `${pageNum}` === `${page}` ? styles.currPage : ""
          }`}
          onClick={() => onPageClick(page)}
        >
          {page}
        </button>
      )}
      {page2 && page2 != 0 && (
        <button
          className={`${styles.pageButton} ${
            `${pageNum}` === `${page2}` ? styles.currPage : ""
          }`}
          onClick={() => onPageClick(page2)}
        >
          {page2}
        </button>
      )}
      {page3 && page3 != 0 && (
        <button
          className={`${styles.pageButton} ${
            `${pageNum}` === `${page3}` ? styles.currPage : ""
          }`}
          onClick={() => onPageClick(page3)}
        >
          {page3}
        </button>
      )} */}
      {/* {maxPage > 3 && (
        <>
          <div>...</div>
          <button
            className={`${styles.pageButton} ${
              `${pageNum}` === `${maxPage}` ? styles.currPage : ""
            }`}
            onClick={() => onPageClick(maxPage)}
          >
            {maxPage}
          </button>
        </>
      )} */}
      <button className={styles.nextButton} onClick={() => nextPage()}>
        <i className="bi bi-chevron-right"></i>
      </button>
    </div>
  );
}
