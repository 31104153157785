import { useState, useEffect } from "react";
import styles from "./AnswerTable.module.scss";
import ContactDetails from "components/Popout/ContactProfile";
import TableChart from "components/Charts/Table/TableChart";
import { combinedQs } from "../Visualization";
import {
  getTitleContainerStyle,
  getTitleStyle,
} from "./Table";
import BucketBreakdown from "./BucketBreakdown/BucketBreakdown";
import { addColumnStrings } from "assets/functions/ObjectFunctions";

export default function ToLastTable({
  data,
  viz,
  canSeeContactInfo,
  inEdit,
  projects,
  newChart,
  setCsvData,
  filterSubtitle,
  onSaveSort,
  spreadsheetmode,
  togglespreadsheet,
  height,
  setOutsideData,
  setUpOutsideDataCounter,
  custom_fields,
  refetch,
  setShowDrill,
}) {
  const [dataArray, setDataArray] = useState([]);
  const [headers, setHeaders] = useState();
  const [contact, setContact] = useState(null);
  const [breakout, setBreakout] = useState(null);

  const vizQs = combinedQs(viz);

  function findQuestion(qId) {
    let q = vizQs.find((q) => q.id === qId);
    if (q) return q;

    for (let s of data.correlatedSurveys) {
      for (let qst of s.questions) {
        if (qst.id === qId) return qst;
      }
    }
    return null;
  }

  function isRanking(qId) {
    let q = findQuestion(qId);
    return q.choiceQuestion?.isRanking;
  }

  function getMatrixAnswer(answer) {
    let matrixAnswer = JSON.parse(answer.matrixAnswer);
    let q = findQuestion(answer.questionId);
    let string = "";
    for (let option of q.matrixQuestion.options) {
      if (
        viz.designSettings.showOnly &&
        !viz.designSettings.showOnly.includes(option)
      ) {
        continue;
      }
      if (option in matrixAnswer) {
        if (string) {
          string += "\n";
        }
        string += option + ": ";
        if (q.choiceQuestion) {
          let choiceAnswer = matrixAnswer[option];
          const ranking = isRanking(answer.questionId);
          for (let i = 0; i < choiceAnswer.length; i++) {
            if (ranking) {
              string += i + 1 + ": ";
            }
            string += choiceAnswer[i];
            if (i < choiceAnswer.length - 1) {
              if (ranking) {
                string += "  ";
              } else {
                string += ", ";
              }
            }
          }
        } else {
          string += matrixAnswer[option];
        }
      }
    }
    if (string) {
      return <div style={{ whiteSpace: "pre" }}>{string}</div>;
    }
    return string;
  }

  function getRankingAnswer(answer) {
    let string = "";
    for (let i = 0; i < answer.choiceAnswer.length; i++) {
      if (string) string += " ";
      string += i + 1 + ": ";
      string += answer.choiceAnswer[i];
    }
    return string;
  }

  function getPrettyAnswer(answer) {
    if (answer.choiceAnswer) {
      if (isRanking(answer.questionId)) {
        return getRankingAnswer(answer);
      }
      let string = "";
      for (let i = 0; i < answer.choiceAnswer.length; i++) {
        if (string) {
          string += ", ";
        }
        string += answer.choiceAnswer[i];
      }
      return string;
    }
    if (answer.scaleAnswer || answer.scaleAnswer === 0) {
      return answer.scaleAnswer;
    }
    if (answer.textAnswer) {
      return answer.textAnswer;
    }
    if (answer.matrixAnswer) {
      return getMatrixAnswer(answer);
    }
    return "Did not answer";
  }

  function getAnswer(answer) {
    if (answer.choiceAnswer) {
      return answer.choiceAnswer;
    }
    if (answer.scaleAnswer || answer.scaleAnswer === 0) {
      return answer.scaleAnswer;
    }
    if (answer.textAnswer) {
      return answer.textAnswer;
    }
    if (answer.matrixAnswer) {
      return answer.matrixAnswer;
    }
    return "Did not answer";
  }

  const style = (value) => <span className={styles.text}>{value}</span>;

  const initHeaders = [
    {
      name: "First Name",
      accessor: "firstName",
      cell_style: style,
    },
    {
      name: "Last Name",
      accessor: "lastName",
      cell_style: style,
    },
    {
      name: "Email",
      accessor: "email",
      cell_style: style,
    },
  ];

  function getColumns() {
    let columns = [];
    if (!viz.designSettings.toLastOrder) {
      setHeaders([]);
      return;
    }

    let tableFields = viz.designSettings.toLastFields;
    for (let field of viz.designSettings.toLastOrder) {
      if (tableFields[field].show) {
        if (tableFields[field].isBucket) {
          if (!tableFields[field].hideOverall) {
            columns.push({
              name: tableFields[field]?.name,
              accessor: field,
              cell_style: style,
              onClick: (obj) => handleBucketClick(obj, field),
            });
          }

          if (tableFields[field].acc) {
            let accName = tableFields[field].accName
              ? tableFields[field].accName
              : "Accuracy";
            columns.push({
              name: tableFields[field].hideOverall
                ? tableFields[field].name + " " + accName
                : "- " + accName,
              accessor: "acc_" + field,
              cell_style: style,
              onClick: (obj) => handleBucketClick(obj, field),
            });
          }

          if (tableFields[field].breakdown) {
            let bName = tableFields[field].bName
              ? tableFields[field].bName
              : "Breakdown";
            columns.push({
              name: tableFields[field].hideOverall
                ? tableFields[field].name + " " + bName
                : "- " + bName,
              accessor: "breakdown_" + field,
              cell_style: style,
              onClick: (obj) => handleBucketClick(obj, field),
            });
          }
        } else {
          columns.push({
            name: tableFields[field]?.name ? tableFields[field].name : field,
            accessor: field,
            cell_style: style,
          });
        }

        // columns.push({
        //   name:
        //     tableFields[field]?.name !== undefined
        //       ? tableFields[field].name
        //       : field,
        //   accessor: field,
        //   cell_style: style,
        //   onClick: tableFields[field].isBucket
        //     ? (obj) => handleBucketClick(obj, field)
        //     : undefined,
        // });
      }
    }

    setHeaders(columns);
  }

  function fillInDots(dataField, contact) {
    dataField.id = contact.id;
    for (let field of viz.designSettings.toLastOrder) {
      if (field in contact) {
        dataField[field] = "...";
      }
    }
  }

  function getDataField(answer, contact, correlatedQMap, correlatedSurveyMap) {
    let dataField = {};

    if (canSeeContactInfo) {
      dataField = { ...contact };
    } else {
      fillInDots(dataField, contact);
    }

    if (answer.questionId in viz.designSettings.toLastFields) {
      dataField[answer.questionId] = getPrettyAnswer(answer);
      dataField[answer.questionId + "real"] = getAnswer(answer); // filtering purposes
    } else if (correlatedQMap[answer.questionId]) {
      dataField["last" + correlatedQMap[answer.questionId]] =
        getPrettyAnswer(answer);
      dataField["last" + correlatedQMap[answer.questionId] + "real"] =
        getAnswer(answer);
      dataField.lastSurvey = correlatedSurveyMap[answer.questionId];
    } else {
      return null;
    }

    return dataField;
  }

  function getAnswerBucketLabels(result, key) {
    let overall = "";
    let acc = "";
    let breakdown = "";

    let props = [];
    if (Array.isArray(result)) {
      props = result;
    } else if (key in viz.designSettings.toLastFields) {
      let bucketField = viz.designSettings.toLastFields[key];

      props = [result.overall];
      if (bucketField?.includeAll) {
        for (let b in result.breakdown) {
          if (b !== result.overall && result.breakdown[b].length) {
            props.push(b);
          }
        }
      }

      let num =
        typeof result.acc === "string"
          ? Number.parseFloat(result.acc)
          : result.acc;
      acc = num * 100 + "%";

      for (let prop in result.breakdown) {
        if (bucketField?.filter && !bucketField.filter.includes(prop)) {
          continue;
        }
        let pieces = result.breakdown[prop];
        if (pieces.length) {
          if (breakdown) {
            breakdown += "\n";
          }
          breakdown += "- " + prop + ": ";

          let string = "";
          for (let piece of pieces) {
            if (string) {
              string += " & ";
            }
            string += piece;
          }
          breakdown += string;
        }
      }
    }

    for (let prop of props) {
      if (overall) {
        overall += ", ";
      }
      overall += prop;
    }

    return [overall, acc, breakdown];
  }

  function addBuckets(dataField, bucketMap, answer, correlatedQMap) {
    let buckets = bucketMap[answer.questionId];
    let answerBuckets = JSON.parse(answer.buckets);
    for (let bucket of buckets) {
      if (bucket.id in viz.designSettings.toLastFields) {
        if (answerBuckets[bucket.name]) {
          let key = correlatedQMap[answer.questionId]
            ? "last" + bucket.id
            : bucket.id;

          let [overall, acc, breakdown] = getAnswerBucketLabels(
            answerBuckets[bucket.name],
            key
          );

          dataField[key] = overall;
          dataField[key + "real"] = answerBuckets[bucket.name];
          dataField["acc_" + key] = acc;
          dataField["breakdown_" + key] = breakdown;

          dataField[key + "info"] = {
            answerId: answer.id,
            bucketName: bucket.name,
            bucketId: bucket.id,
          };
        }
      }
    }
  }

  function getData() {
    let dataMap = {};
    let bucketMap = {};
    for (let q of vizQs) {
      if (q.textQuestion && q.textQuestion.bucket) {
        bucketMap[q.id] = q.textQuestion.bucket;
      }
    }

    let correlatedQMap = {};
    let correlatedSurveyMap = {};

    for (let survey of data.correlatedSurveys) {
      for (let c of survey.correlated) {
        correlatedQMap[c.to] = c.from;
        correlatedSurveyMap[c.to] = survey.surveyName;
      }
      for (let q of survey.questions) {
        if (q.textQuestion && q.textQuestion.bucket) {
          bucketMap[q.id] = q.textQuestion.bucket;
        }
      }
    }

    for (let answer of data.answers) {
      if (!answer.participation.contact) {
        continue; // Comparing to last
      }
      let contact = { ...answer.participation?.contact };
      if (contact) {
        addColumnStrings(contact, custom_fields);
      }

      let dataField = getDataField(
        answer,
        contact,
        correlatedQMap,
        correlatedSurveyMap
      );
      if (!dataField) {
        continue;
      }

      if (bucketMap[answer.questionId] && answer.buckets) {
        addBuckets(dataField, bucketMap, answer, correlatedQMap);
      }

      if (!dataMap[dataField.id]) {
        dataMap[dataField.id] = dataField;
      } else {
        let orig = dataMap[dataField.id];
        dataMap[dataField.id] = { ...orig, ...dataField };
      }
    }

    let rows = [];
    for (let key in dataMap) {
      let row = dataMap[key];
      let include = true;
      for (let q of vizQs) {
        if (!q.matrixQuestion && q.id in row) {
          if (q.scaleQuestion) {
            let filter = viz.designSettings.toLastFilter.scale;
            if ("last" + q.id in row) {
              let thisTime = row[q.id];
              let last = row["last" + q.id];
              let arrow = "";
              let color = "";
              let deg = 0;
              if (thisTime != last) {
                let range = q.scaleQuestion.max - q.scaleQuestion.min;
                let it = Math.round((Math.abs(thisTime - last) / range) * 90);
                deg = 45 - it;
              }

              if (q.isScore) {
                [arrow, color, include] = npsArrow(
                  row[q.id],
                  row["last" + q.id],
                  filter
                );
                if (!include) {
                  break;
                }
              } else if (thisTime < last) {
                if (filter && !filter.lower) {
                  include = false;
                  break;
                }
                arrow = "arrow-down-right";
                color = "red";
              } else if (thisTime > last) {
                if (filter && !filter.higher) {
                  include = false;
                  break;
                }
                arrow = "arrow-up-right";
                color = "green";
              } else {
                if (filter && !filter.same) {
                  include = false;
                  break;
                }
                arrow = "arrows";
                color = "yellow";
              }

              if (thisTime < last) {
                deg *= -1;
              }

              let arrowDiv = (
                <div style={{ transform: `rotate(${deg}deg)` }}>
                  <i className={`bi bi-${arrow} ${styles[color]} `}></i>
                </div>
              );
              if (viz.designSettings.sideBySide) {
                row[q.id] = (
                  <div className={styles.arrowDiv}>
                    <div className={styles.last}>{row["last" + q.id]} </div>
                    {arrowDiv}
                    {row[q.id]}
                  </div>
                );
              } else {
                row[q.id] = (
                  <div className={styles.arrowDiv}>
                    {row[q.id]} {arrowDiv}
                  </div>
                );
              }
            } else if (filter) {
              include = false;
            }
          } else if (q.choiceQuestion) {
            let filter = viz.designSettings.toLastFilter.choiceFilters;
            if (
              filter &&
              !passesToFromFilter(
                row[q.id + "real"],
                row["last" + q.id + "real"],
                filter
              )
            ) {
              include = false;
            }
          } else if (q.textQuestion && bucketMap[q.id]) {
            // If is a bucket column and there are filters on this bucket
            let buckets = bucketMap[q.id];
            for (let bucket of buckets) {
              let filter =
                viz.designSettings.toLastFilter[bucket.name + "bucketFilters"];
              if (
                filter &&
                row[bucket.id] &&
                !passesToFromFilter(
                  row[bucket.id + "real"],
                  row["last" + bucket.id + "real"],
                  filter
                )
              ) {
                include = false;
              }
            }
          }
        }
      }

      if (include) {
        rows.push(dataMap[key]);
      }
    }

    setDataArray(rows);
  }

  function npsArrow(thisTime, last, filter) {
    let dir = "";
    let color = "";
    let include = true;

    if (thisTime == last && filter && !filter.same) {
      include = false;
    }

    if (thisTime == 9 || thisTime == 10) {
      color = "green";
    } else if (thisTime == 7 || thisTime == 8) {
      color = "orange";
    } else {
      color = "red";
    }

    if (last == 9 || last == 10) {
      if (thisTime == 9 || thisTime == 10) {
        if (thisTime > last) {
          if (filter && !filter.higher) include = false;
          dir = "up";
        } else if (thisTime < last) {
          if (filter && !filter.lower) include = false;
          dir = "down";
        }
      } else if (thisTime == 7 || thisTime == 8) {
        if (filter && !filter.promToPass && !filter.lower) include = false;
        dir = "down";
      } else {
        if (filter && !filter.promToDet && !filter.lower) include = false;
        dir = "down";
      }
    } else if (last == 7 || last == 8) {
      if (thisTime == 9 || thisTime == 10) {
        if (filter && !filter.passToProm && !filter.higher) include = false;
        dir = "up";
      } else if (thisTime == 7 || thisTime == 8) {
        if (thisTime > last) {
          if (filter && !filter.higher) include = false;
          dir = "up";
        } else if (thisTime < last) {
          if (filter && !filter.lower) include = false;
          dir = "down";
        }
      } else {
        if (filter && !filter.passToDet && !filter.lower) include = false;
        dir = "down";
      }
    } else {
      if (thisTime == 9 || thisTime == 10) {
        if (filter && !filter.detToProm && !filter.higher) include = false;
        dir = "up";
      } else if (thisTime == 7 || thisTime == 8) {
        if (filter && !filter.detToPass && !filter.higher) include = false;
        dir = "up";
      } else {
        if (thisTime > last) {
          if (filter && !filter.higher) include = false;
          dir = "up";
        } else if (thisTime < last) {
          if (filter && !filter.lower) include = false;
          dir = "down";
        }
      }
    }

    let arrows = "arrow" + (dir ? `-${dir}-right` : "s");

    return [arrows, color, include];
  }

  function setFakeData() {
    let extra = [
      {
        name: "Answer",
        accessor: "answer",
        cell_style: style,
      },
    ];
    setHeaders([...initHeaders, ...extra]);

    let data = [
      {
        firstName: "John",
        lastName: "Doe",
        email: "john@doe.com",
        answer: "Okay",
      },
      {
        firstName: "Jane",
        lastName: "Doe",
        email: "jane@doe.com",
        answer: "Great",
      },
      {
        firstName: "John Jr",
        lastName: "Doe",
        email: "johnjr@doe.com",
        answer: "Good",
      },
      {
        firstName: "Jane Jr",
        lastName: "Doe",
        email: "janejr@doe.com",
        answer: "Great",
      },
    ];
    setDataArray(data);
  }

  function passesToFromFilter(thisTimes, lastTimes, filters) {
    if (thisTimes && !Array.isArray(thisTimes)) {
      thisTimes = getAnswerBucketLabels(thisTimes)[0];
    }
    if (lastTimes && !Array.isArray(lastTimes)) {
      lastTimes = getAnswerBucketLabels(lastTimes)[0];
    }

    let passesOne = false;
    for (let toFrom of filters) {
      let passesFrom = true;
      if (toFrom.from.length) {
        if (lastTimes) {
          passesFrom = toFrom.from.some((s) => lastTimes.includes(s));
        } else {
          passesFrom = false;
        }
      }

      let passesTo = true;
      if (toFrom.to.length) {
        if (thisTimes) {
          passesTo = toFrom.to.some((s) => thisTimes.includes(s));
        } else {
          passesTo = false;
        }
      }

      if (passesFrom && passesTo) {
        passesOne = true;
        break;
      }
    }
    return passesOne;
  }

  useEffect(() => {
    if (newChart && vizQs.length === 0) {
      setFakeData();
    } else if (data) {
      getColumns();
      getData();
    }
  }, [viz, data]);

  function handleRowClick(obj) {
    var selection = window.getSelection();
    if (!selection.toString()) {
      let person = { ...obj };
      if (!person.anon) {
        delete person.anon;
        setContact(person);
        setShowDrill(true);
      }
    }
  }

  function handleBucketClick(obj, id) {
    if (inEdit) return;
    var selection = window.getSelection();
    if (!selection.toString() && selection.isCollapsed) {
      if (obj[id] && obj[id + "info"]) {
        let info = obj[id + "info"];

        let answer = data.answers.find((a) => a.id === info.answerId);
        if (answer && answer.buckets) {
          let answerBuckets = JSON.parse(answer.buckets);
          let answerBucket = answerBuckets[info.bucketName];
          if (answerBucket && !Array.isArray(answerBucket)) {
            setBreakout({
              answerBucket: answerBucket,
              bucketId: info.bucketId,
              contact: obj.anon ? null : { ...obj },
              answer: answer,
            });
            setShowDrill(true);
            return;
          }
        }
      }
      handleRowClick(obj);
    }
  }

  function shouldSortInLast(colId) {
    if (
      viz.designSettings.toLastFields[colId] &&
      viz.designSettings.toLastFields[colId].project
    ) {
      let q = vizQs.find((q) => q.id === colId);
      if (q?.scaleQuestion && !q?.matrixQuestion) {
        return true;
      }
    }
    return false;
  }

  function sortInLast(direction, dataCopy, col, sortCopy) {
    dataCopy.sort((row1, row2) => {
      let a = row1[col];
      let b = row2[col];

      if (!a && a !== 0) {
        a = -1;
      }
      if (!b && b !== 0) {
        b = -1;
      }

      if (typeof a === "object") {
        a = row1[col + "real"];
      }
      if (typeof b === "object") {
        b = row2[col + "real"];
      }

      if ("last" + col in row1) {
        if ("last" + col in row2) {
          let az = row1["last" + col];
          let bz = row2["last" + col];

          let aDir = 0;
          if (a < az) {
            aDir = -1;
          }
          if (a > az) {
            aDir = 1;
          }

          let bDir = 0;
          if (b < bz) {
            bDir = -1;
          }
          if (b > bz) {
            bDir = 1;
          }

          if (aDir == bDir) {
            if (a == b && !sortCopy.some((s) => s.col === "last" + col)) {
              return direction === "up" ? az - bz : bz - az;
            }
            return direction === "down" ? a - b : b - a;
          } else {
            return direction === "down" ? aDir - bDir : bDir - aDir;
          }
        } else {
          return -1;
        }
      } else if ("last" + col in row2) {
        return 1;
      }

      // normal / numbers with no arrows
      return direction === "down" ? a - b : b - a;
    });
  }

  return (
    <>
      {headers && dataArray && (
        <TableChart
          initHeaders={headers}
          data={dataArray}
          asChart
          onRowClick={inEdit || !canSeeContactInfo ? undefined : handleRowClick}
          titleStyle={getTitleStyle(viz)}
          tableTitle={viz.designSettings.hasTitle ? viz.title : ""}
          setExternalCsvData={setCsvData}
          subtitle={filterSubtitle ? filterSubtitle : false}
          tableSort={viz.designSettings?.tableSort}
          onSaveSort={onSaveSort}
          inEdit={inEdit}
          toggleSpreadsheet={togglespreadsheet}
          spreadsheetmode={spreadsheetmode}
          titleContainerStyle={getTitleContainerStyle(viz)}
          color={viz.designSettings.tableColor}
          height={height}
          setOutsideData={setOutsideData}
          setUpOutsideDataCounter={setUpOutsideDataCounter}
          sortInLast={sortInLast}
          shouldSortInLast={shouldSortInLast}
        />
      )}
      {contact && (
        <ContactDetails
          onClose={() => {
            setContact(null);
            setShowDrill(false);
          }}
          contact={contact}
        ></ContactDetails>
      )}

      {breakout && (
        <BucketBreakdown
          bucketId={breakout.bucketId}
          answerBucket={breakout.answerBucket}
          contact={breakout.contact}
          answer={breakout.answer}
          onClose={() => {
            setBreakout(null);
            setShowDrill(false);
          }}
          refetch={refetch}
        />
      )}
    </>
  );
}
