import { useEffect } from "react";
import AnswerTable from "./AnswerTable";
import DataTable from "./DataTable";
import DrillTable from "./DrillTable";
import PivotAnswerTable from "./PivotAnswerTable";
import ToLastTable from "./ToLastTable";

export function getTitleContainerStyle(viz) {
  return {
    alignItems: viz.designSettings.titleAlignment,
    backgroundColor: viz.designSettings.titleBackgroundColor,
    borderRadius: viz.designSettings.titleBorderRadius,
    paddingTop: viz.designSettings.paddingTopTitle + 10,
    paddingBottom: viz.designSettings.paddingBottomTitle,
    paddingLeft: viz.designSettings.paddingLeftTitle + 10,
    paddingRight: viz.designSettings.paddingRightTitle + 10,
    fontFamily: viz.designSettings.titleFont,
  };
}

export function getTitleStyle(viz) {
  return {
    color: viz.designSettings.titleColor,
    fontSize: viz.designSettings.valueTitleSize,
    textAlign: viz.designSettings.titleAlignment,
  };
}

export default function Table({
  answers,
  filters,
  onClose,
  viz,
  canSeeContactInfo,
  inEdit,
  drill,
  asChart,
  projects,
  surveyTags,
  onSegClick,
  newChart,
  setCsvData,
  custom_fields,
  filterSubtitle,
  onSaveSort,
  spreadsheetmode,
  togglespreadsheet,
  chartData,
  setChartData,
  update,
  saveData,
  height,
  setOutsideData,
  setUpOutsideDataCounter,
  refetch,
  setShowDrill,
}) {
  useEffect(() => {
    if (update && saveData) {
      saveData("table");
    }
  }, []);

  return (
    <>
      {asChart && (
        <>
          {(viz.pivotString || viz.designSettings.pivotOptions) && (
            <PivotAnswerTable
              answers={answers}
              filters={filters}
              viz={viz}
              canSeeContactInfo={canSeeContactInfo}
              inEdit={inEdit}
              projects={projects}
              surveyTags={surveyTags}
              onSegClick={onSegClick}
              setCsvData={setCsvData}
              custom_fields={custom_fields}
              filterSubtitle={filterSubtitle}
              onSaveSort={onSaveSort}
              togglespreadsheet={togglespreadsheet}
              height={height}
              setOutsideData={setOutsideData}
              setUpOutsideDataCounter={setUpOutsideDataCounter}
            />
          )}
          {!viz?.pivotString && (
            <>
              {viz.designSettings.toLast && (
                <ToLastTable
                  data={answers}
                  viz={viz}
                  canSeeContactInfo={canSeeContactInfo}
                  inEdit={inEdit}
                  projects={projects}
                  newChart={newChart}
                  setCsvData={setCsvData}
                  filterSubtitle={filterSubtitle}
                  onSaveSort={onSaveSort}
                  spreadsheetmode={spreadsheetmode}
                  togglespreadsheet={togglespreadsheet}
                  height={height}
                  setOutsideData={setOutsideData}
                  setUpOutsideDataCounter={setUpOutsideDataCounter}
                  custom_fields={custom_fields}
                  refetch={refetch}
                  setShowDrill={setShowDrill}
                />
              )}

              {!viz.designSettings.toLast && (
                <AnswerTable
                  answers={answers}
                  viz={viz}
                  canSeeContactInfo={canSeeContactInfo}
                  inEdit={inEdit}
                  projects={projects}
                  newChart={newChart}
                  setCsvData={setCsvData}
                  filterSubtitle={filterSubtitle}
                  onSaveSort={onSaveSort}
                  spreadsheetmode={spreadsheetmode}
                  togglespreadsheet={togglespreadsheet}
                  height={height}
                  setOutsideData={setOutsideData}
                  setUpOutsideDataCounter={setUpOutsideDataCounter}
                  custom_fields={custom_fields}
                  refetch={refetch}
                  setShowDrill={setShowDrill}
                />
              )}
            </>
          )}
        </>
      )}
      {drill && (
        <DrillTable
          answers={answers}
          filters={filters}
          viz={viz}
          custom_fields={custom_fields}
          inEdit={inEdit}
          projects={projects}
          surveyTags={surveyTags}
          drill={drill}
          onClose={onClose}
          toggleSpreadsheet={togglespreadsheet}
        />
      )}
      {!asChart && !drill && (
        <DataTable
          answers={answers}
          filters={filters}
          custom_fields={custom_fields}
          onClose={onClose}
          viz={viz}
          inEdit={inEdit}
          projects={projects}
          togglespreadsheet={togglespreadsheet}
          spreadsheetmode={spreadsheetmode}
          setChartData={setChartData}
          chartData={chartData}
        />
      )}
    </>
  );
}
