import React, { useEffect, useState } from "react";
import styles from "./Filter.module.scss";

import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import { forEach, getSortedArray } from "assets/functions/ArrayFunctions";
import AnswersFilter from "./AnswersFilter";

export default function Filter({
  custom_fields,
  chosenFilter,
  updateChosenFilters,
  externalFilter,
  disabled,
  projects,
  style,
}) {
  const [visible, setVisible] = useState("");
  const [clearing, setClearing] = useState(false);

  function changeFilter(field, property, e) {
    let temp = { ...chosenFilter };
    if (!(field.id in temp)) {
      temp[field.id] = {};
      temp[field.id].name = field.name;
      temp[field.id].properties = [];
    }
    if (e.target.checked) {
      temp[field.id].properties.push(property);
    } else {
      let propertyIndex = chosenFilter[field.id].properties.findIndex(
        (p) => p === property
      );
      temp[field.id].properties.splice(propertyIndex, 1);
      if (temp[field.id].properties.length === 0) {
        delete temp[field.id];
      }
    }
    updateChosenFilters(temp);
  }

  function checkAll(field, val) {
    let temp = { ...chosenFilter };
    if (val) {
      let index = custom_fields.findIndex((f) => f.id === field.id);
      let props = [...custom_fields[index].properties];
      temp[field.id] = { name: field.name, properties: props };
    } else {
      delete temp[field.id];
    }

    updateChosenFilters(temp);
  }

  function clearAll() {
    updateChosenFilters({});
    setClearing(true);
    setTimeout(() => setClearing(false), 1000);
  }

  useEffect(() => {
    if (disabled) {
      setVisible("");
    }
  }, [disabled]);

  return (
    <div className={styles.accordionsContainer} style={style}>
      {projects && projects.length > 1 && (
        <ProjectFilter
          projects={projects}
          updateChosenFilters={updateChosenFilters}
          chosenFilter={chosenFilter}
          visible={visible === "surveys"}
          setVisible={setVisible}
          externalFilter={externalFilter}
        ></ProjectFilter>
      )}

      {custom_fields
        ?.filter((f) => f.filterable !== false)
        .map((field, i) => (
          <>
            {field?.properties?.length > 0 && (
              <FilterAccordian
                key={i}
                column={field}
                name={field.displayName}
                onCheck={(val) => checkAll(field, val)}
                changeFilter={changeFilter}
                chosenFilter={chosenFilter}
                externalFilter={externalFilter}
                visible={visible === field.id}
                setVisible={setVisible}
                disabled={disabled ? disabled.includes(field.id) : null}
              ></FilterAccordian>
            )}
          </>
        ))}

      {projects && (
        <FilterByAnswer
          projects={projects}
          updateChosenFilters={updateChosenFilters}
          chosenFilter={chosenFilter}
          visible={visible === "answers"}
          setVisible={setVisible}
          externalFilter={externalFilter}
        ></FilterByAnswer>
      )}

      {(clearing || Object.keys(chosenFilter).length > 0) && (
        <div className={styles.clearFilters}>
          <div className={styles.clearFiltersBtn} onClick={clearAll}>
            <div className={styles.funnels}>
              <i className="bi bi-funnel-fill"></i>
              {clearing && (
                <i
                  className={`bi bi-funnel ${styles.emptyFunnel} ${styles.empty}`}
                ></i>
              )}
            </div>
            <i className="bi bi-x"></i>
          </div>
        </div>
      )}

      {custom_fields?.length === 0 && (
        <div className={styles.nothingToFilter}>
          Participants had no info to filter
        </div>
      )}
    </div>
  );
}

const FilterAccordian = ({
  name,
  onCheck,
  changeFilter,
  column,
  chosenFilter,
  externalFilter,
  visible,
  setVisible,
  disabled,
}) => {
  function initListedItems() {
    if (column.id in chosenFilter) {
      let filterList = chosenFilter[column.id].properties;

      let props = column.properties.toSorted((a, b) => {
        let includesA = filterList.includes(a);
        let includesB = filterList.includes(b);

        if (includesA) {
          if (includesB) {
            return 0;
          }
          return -1;
        }
        if (includesB) {
          return 1;
        }
        return 0;
      });
      return props;
    }

    return column.properties;
  }

  const [listedItems, setListedItems] = useState(initListedItems());
  const [topOfList, setTopOfList] = useState(listedItems.slice(0, 100));
  const [searchString, setSearchString] = useState("");

  const toggleVisibility = (e) => {
    let searchField = document.getElementById("searchInput for " + name);
    if (!(searchField && e.target === searchField)) {
      setVisible((v) => (v === column.id ? "" : column.id));
      setSearchString("");
      let items = initListedItems();
      setListedItems(items);
      setTopOfList(items.slice(0, 100));
    }
  };

  function onScroll(e) {
    if (topOfList.length < listedItems.length) {
      var scrollableContainer = e.target;

      let distanceToBottom =
        scrollableContainer.scrollHeight -
        (scrollableContainer.scrollTop + scrollableContainer.clientHeight);

      if (distanceToBottom <= 50) {
        let nextlength = topOfList.length + 100;
        setTopOfList(listedItems.slice(0, nextlength));
      }
    }
  }

  function chosenCount() {
    let props = [];
    if (chosenFilter[column.id]) {
      props = [...chosenFilter[column.id].properties];
    }
    if (externalFilter && externalFilter[column.id]) {
      for (let prop of externalFilter[column.id].properties) {
        if (!props.includes(prop)) {
          props.push(prop);
        }
      }
    }
    return props.length;
  }

  const handleCheck = (e) => {
    let val = e.target.checked;
    onCheck(val);
  };

  function handleSearch(e) {
    let string = e.target.value.toLowerCase();
    let applicable = [];
    let left = [];

    forEach(column.properties, (item) => {
      let substring = item.toLowerCase().substring(0, string.length);
      if (substring === string.toLowerCase()) {
        applicable.push(item);
      } else {
        left.push(item);
      }
    });
    forEach(left, (item) => {
      if (item.toLowerCase().includes(string)) {
        applicable.push(item);
      }
    });
    setListedItems(applicable);
    setTopOfList(applicable.slice(0, 100));
    setSearchString(string);
  }

  function checkCheck(field, item) {
    if (field.id in chosenFilter) {
      if (chosenFilter[field.id].properties.find((p) => p === item)) {
        return true;
      }
    }

    return checkExternal(field, item);
  }

  function checkExternal(field, item) {
    if (externalFilter && field.id in externalFilter) {
      if (externalFilter[field.id].properties.find((p) => p === item)) {
        return true;
      }
    }
    return false;
  }

  function isHeaderChecked() {
    if (column.id in chosenFilter) {
      for (let prop of column.properties) {
        if (!chosenFilter[column.id].properties.includes(prop)) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  return (
    <div
      key={column.name}
      className={`${styles.accordian} ${
        visible ? styles.accordionactivecontainer : ""
      }`}
    >
      <div
        className={`${styles.header} ${visible ? styles.accordionactive : ""} ${
          disabled ? styles.disabled : ""
        }`}
        onClick={disabled ? null : toggleVisibility}
      >
        <div className={styles.checknName}>
          <Checkbox
            checked={isHeaderChecked()}
            onChange={handleCheck}
            disabled={disabled}
          />
          {!visible && <div>{column.displayName}</div>}
          {visible && (
            <input
              id={"searchInput for " + column.displayName}
              type="text"
              placeholder={"Search " + column.displayName + "..."}
              className={styles.search}
              value={searchString}
              onChange={handleSearch}
            ></input>
          )}
        </div>
        {!disabled && (
          <div className={styles.finalItems}>
            <span className={styles.chosenCount}>{chosenCount()}</span>
            <span
              className={styles.accordionicon}
              onClick={visible ? toggleVisibility : null}
            >
              <i
                className="bi bi-caret-left-fill"
                onClick={visible ? toggleVisibility : null}
              ></i>
            </span>
          </div>
        )}
      </div>
      {visible && (
        <div
          className={styles.body}
          id={`filters for ${column.id}`}
          onScroll={onScroll}
        >
          {topOfList.map((item, i) => (
            <React.Fragment key={i}>
              {column.name != "" && column.name != "null" && (
                <div key={item} className={styles.item}>
                  <Checkbox
                    checked={checkCheck(column, item)}
                    onChange={(e) => changeFilter(column, item, e)}
                    color={checkExternal(column, item) ? "#7fcfd3" : undefined}
                    disabled={checkExternal(column, item)}
                  ></Checkbox>
                  <span>{item}</span>
                  {checkExternal(column, item) && (
                    <div className={styles.pageFilter}>Page Filter</div>
                  )}
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

function ProjectFilter({
  projects,
  updateChosenFilters,
  chosenFilter,
  visible,
  setVisible,
  externalFilter,
}) {
  function initListedItems() {
    let inOrder = getSortedArray(projects, (a, b) => {
      if (a.startedAt) {
        if (b.startedAt) {
          let aDate = new Date(a.startedAt);
          let bDate = new Date(b.startedAt);
          return bDate.getTime() - aDate.getTime();
        }
        return -1;
      }
      if (b.startedAt) return 1;
      return 0;
    });

    if (chosenFilter?.surveys) {
      let includedList = chosenFilter.surveys;
      let sorted = getSortedArray(inOrder, (a, b) => {
        let includesA = includedList.includes(a.id);
        let includesB = includedList.includes(b.id);

        if (includesA) {
          if (includesB) {
            return 0;
          }
          return -1;
        }
        if (includesB) {
          return 1;
        }
        return 0;
      });
      return sorted;
    }
    return inOrder;
  }

  const [listedProjects, setListedProjects] = useState(initListedItems());
  const [searchString, setSearchString] = useState("");

  const toggleVisibility = (e) => {
    let searchField = document.getElementById("searchInput for surveys");
    if (!(searchField && e.target === searchField)) {
      setVisible((v) => (v === "surveys" ? "" : "surveys"));
      setSearchString("");
      setListedProjects(initListedItems());
    }
  };

  function handleSearch(e) {
    let string = e.target.value.toLowerCase();
    let applicable = [];
    let left = [];

    forEach(projects, (proj) => {
      let substring = proj.name.toLowerCase().substring(0, string.length);
      if (substring === string.toLowerCase()) {
        applicable.push(proj);
      } else {
        left.push(proj);
      }
    });
    forEach(left, (proj) => {
      if (proj.name.toLowerCase().includes(string)) {
        applicable.push(proj);
      }
    });
    setListedProjects(applicable);
    setSearchString(string);
  }

  function checkAllProjects(val) {
    let temp = { ...chosenFilter };
    if (val) {
      temp.surveys = projects.map((p) => {
        return { id: p.id, name: p.name };
      });
    } else {
      delete temp.surveys;
    }

    updateChosenFilters(temp);
  }

  function changeFilter(survey, e) {
    let temp = { ...chosenFilter };
    if (!temp?.surveys) {
      temp.surveys = [];
    }
    if (e.target.checked) {
      temp.surveys.push({ id: survey.id, name: survey.name });
    } else {
      let ind = temp.surveys.findIndex((s) => s.id === survey.id);
      temp.surveys.splice(ind, 1);
      if (temp.surveys.length === 0) {
        delete temp.surveys;
      }
    }
    updateChosenFilters(temp);
  }

  function checkCheck(project) {
    if (chosenFilter?.surveys) {
      if (chosenFilter.surveys.find((s) => s.id === project.id)) {
        return true;
      }
    }
    return checkExternal(project);
  }

  function checkExternal(project) {
    if (externalFilter && externalFilter?.surveys) {
      if (externalFilter.surveys.find((s) => s.id === project.id)) {
        return true;
      }
    }
    return false;
  }

  function isHeaderChecked() {
    if (chosenFilter?.surveys) {
      for (let proj of projects) {
        if (!chosenFilter.surveys.find((s) => s.id === proj.id)) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  function getChosenCount() {
    let surveys = [];
    if ("surveys" in chosenFilter) {
      surveys = [...chosenFilter.surveys];
    }
    if (externalFilter && "surveys" in externalFilter) {
      for (let survey of externalFilter.surveys) {
        if (!surveys.some((s) => s.id === survey.id)) {
          surveys.push(survey);
        }
      }
    }
    return surveys.length;
  }

  return (
    <div
      key={"surveys"}
      className={`${styles.accordian} ${
        visible ? styles.accordionactivecontainer : ""
      }`}
    >
      <div
        className={`${styles.header} ${visible ? styles.accordionactive : ""}`}
        onClick={toggleVisibility}
      >
        <div className={styles.checknName}>
          <Checkbox
            checked={isHeaderChecked()}
            onChange={(e) => checkAllProjects(e.target.checked)}
          />
          {!visible && <div>Survey</div>}
          {visible && (
            <input
              id={"searchInput for surveys"}
              type="text"
              placeholder={"Search surveys..."}
              className={styles.search}
              value={searchString}
              onChange={handleSearch}
            ></input>
          )}
        </div>

        <div className={styles.finalItems}>
          <span className={styles.chosenCount}>{getChosenCount()}</span>
          <span
            className={styles.accordionicon}
            onClick={visible ? toggleVisibility : null}
          >
            <i
              className="bi bi-caret-left-fill"
              onClick={visible ? toggleVisibility : null}
            ></i>
          </span>
        </div>
      </div>
      {visible && (
        <div className={styles.body}>
          {listedProjects.map((proj) => (
            <div key={proj.name} className={styles.item}>
              <Checkbox
                checked={checkCheck(proj)}
                onChange={(e) => changeFilter(proj, e)}
                color={checkExternal(proj) ? "#7fcfd3" : undefined}
                disabled={checkExternal(proj)}
              ></Checkbox>
              <span>{proj.name}</span>
              {checkExternal(proj) && (
                <div className={styles.pageFilter}>Page Filter</div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function FilterByAnswer({
  projects,
  updateChosenFilters,
  chosenFilter,
  visible,
  setVisible,
  disabled,
  externalFilter,
}) {
  const toggleVisibility = (e) => {
    setVisible((v) => (v === "answers" ? "" : "answers"));
  };

  function getChosenCount() {
    let count = 0;
    if ("answers" in chosenFilter) {
      count = chosenFilter.answers.length;
    }
    if (externalFilter && "answers" in externalFilter) {
      count += externalFilter.answers.length;
    }
    return count;
  }

  return (
    <div
      key={"answers"}
      className={`${styles.accordian} ${
        visible ? styles.accordionactivecontainer : ""
      }`}
    >
      <div
        className={`${styles.header} ${visible ? styles.accordionactive : ""}`}
        onClick={disabled ? null : toggleVisibility}
      >
        <div className={styles.checknName}>
          {/* <Checkbox
            checked={isHeaderChecked()}
            onChange={(e) => onHeaderCheck(e.target.checked)}
          /> */}
          <div
            style={{
              minHeight: "20px",
              paddingLeft: "34px",
              display: "flex",
              alignItems: "center",
            }}
          >
            Answers
          </div>
        </div>

        {!disabled && (
          <div className={styles.finalItems}>
            <span className={styles.chosenCount}>{getChosenCount()}</span>
            <span className={styles.accordionicon} onClick={toggleVisibility}>
              <i
                className="bi bi-caret-left-fill"
                onClick={toggleVisibility}
              ></i>
            </span>
          </div>
        )}
      </div>
      {visible && (
        <AnswersFilter
          projects={projects}
          updateChosenFilters={updateChosenFilters}
          chosenFilter={chosenFilter}
          externalFilter={externalFilter}
        />
      )}
    </div>
  );
}
