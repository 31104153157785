import React, { useEffect, useCallback, useState, useRef } from "react";
import styles from "./BasicTable.module.scss";
import SimpleBar from "simplebar-react";

import "simplebar-react/dist/simplebar.min.css";
import { shortId } from "../EditableTable/utils";
import { TextFieldSimple } from "components/inputs";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Icon from "components/Icon/Icon";
import FieldAdjuster from "pages/contacts/all_contacts/FieldAdjuster";
import SalesforceMapping from "pages/contacts/all_contacts/SalesforceMapping";
import Modal from "components/ReactModal/ReactModal";
import Button from "components/Button/Button";

/**
 * A Table that is used to display data
 * @param {type} initHeaders something
 * @param {type} createTitle something
 * @param {type} createMethod something
 * @param {type} data something
 * @param {type} onRowClick something
 * @param {type} setPageNumber something
 * @param {type} pageNumber something
 * @param {type} bottomLeft something
 * @param {type} bottomRight something
 * @param {boolean} noFilter no filter button
 * @param {boolean} noSearch no search bar
 * @param {type} maxPage something
 * @returns {React.ReactElement} a Table component
 */

export function BasicTable({
  initheaders,
  data,
  onRowClick,
  sort,
  setSort,
  emptyMessage,
  headerSize,
  tableSize,
  padding,
  rowNumbers,
  paginateScroll,
  actionCell,
  onClickActionCell,
  actionCellText,
  onChangeEditableCell,
  onSaveEditableCell,
  select,
  selected,
  changeSelected,
  editableFields,
  overflowVisible,
  moveableColumns,
  style,
  onRowClickSelect,
  headerSettings,
  salesforce,
  user,
  organization,
  addFilter,
  customFields,
}) {
  const [headers, setHeaders] = useState(checkSort(initheaders));
  const [topOfList, setTopOfList] = useState();
  useEffect(() => {
    if (topOfList) {
      setTopOfList(
        paginateScroll && data && data?.length > 0
          ? data?.length > topOfList?.length
            ? data?.slice(0, topOfList?.length)
            : data
          : []
      );
    } else {
      setTopOfList(
        paginateScroll && data && data?.length > 0
          ? data?.length > 50
            ? data?.slice(0, 50)
            : data
          : []
      );
    }
  }, [data]);

  useEffect(() => {
    setHeaders(checkSort(initheaders));
  }, [initheaders]);

  function onScroll(e) {
    if (paginateScroll && topOfList?.length < data?.length) {
      var scrollableContainer = e.target;
      let distanceToBottom =
        scrollableContainer.scrollHeight -
        (scrollableContainer.scrollTop + scrollableContainer.clientHeight);

      if (distanceToBottom <= 50) {
        let nextlength = topOfList?.length + 50;
        setTopOfList(data.slice(0, nextlength));
      }
    }
  }

  function checkSort(heads) {
    for (let i = 0; i < heads?.length; i++) {
      let header = heads[i];
      if (sort?.item === header?.accessor) {
        heads[i].sort = true;
      } else {
        heads[i].sort = false;
      }
    }
    return heads;
  }
  function trimDate(date) {
    let d = new Date(date);
    let niceString = d.toDateString();
    let month = niceString.substring(4, 7);
    let day = niceString.substring(8, 10);
    //eslint-disable-next-line no-unused-expressions
    day.charAt(0) == "0" ? (day = day.charAt(1)) : null;
    let year = niceString.substring(11);
    return month + " " + day + ", " + year;
  }

  function isDate(dateString) {
    // Basic regex for matching YYYY-MM-DD or MM/DD/YYYY format
    const regex =
      /^\d{4}-\d{2}-\d{2}$|^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3})?(?:Z|[+-]\d{2}:\d{2})?$/;

    // Check if the input matches the date format first
    if (!regex.test(dateString)) {
      return false;
    }

    // Now check if it's a valid date using Date object
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  }

  function screenForDate(value) {
    if (isDate(value)) {
      return trimDate(value);
    } else {
      if (typeof value === "object") {
        if (Array.isArray(value)) {
          return "";
        }
      }
      return value;
    }
  }

  function handleSetSort(name, index, canSort) {
    if (canSort) {
      let newSort = { ...sort };
      newSort.item = name;
      if (name === sort.item) {
        newSort.descend = !sort.descend;
      }
      for (let i = 0; i < headers?.length; i++) {
        headers[i].sort = false;
      }
      headers[index].sort = true;
      setSort(newSort);
    }
  }
  function handleSetSortSpecific(name, index, canSort, descend) {
    if (canSort) {
      let newSort = { ...sort };
      newSort.item = name;

      newSort.descend = descend;

      for (let i = 0; i < headers?.length; i++) {
        headers[i].sort = !descend;
      }
      headers[index].sort = descend;
      setSort(newSort);
    }
  }

  const [resizingIndex, setResizingIndex] = useState(null);
  const [originalWidth, setOriginalWidth] = useState(0);
  const [originalX, setOriginalX] = useState(0);
  const [openHeader, setOpenHeader] = useState();
  const [adjustFields, setAdjustFields] = useState(false);
  const [mapColumns, setMapColumns] = useState(false);
  const [changeHeader, setChangeHeader] = useState(false);
  const ref = useRef();

  const handleClickOutside = (event) => {
    if (headerSettings && ref.current && !ref.current.contains(event.target)) {
      setOpenHeader(null);
      setChangeHeader(false);
    }
  };

  useEffect(() => {
    if (headerSettings) {
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        document.removeEventListener("click", handleClickOutside, true);
      };
    }
  }, []);

  const handleMouseDown = (index, event) => {
    setResizingIndex(index);
    setOriginalWidth(event.target.offsetParent.clientWidth);
    setOriginalX(event.pageX);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (resizingIndex !== null) {
      const newWidth = originalWidth + (e.pageX - originalX);
      let newHeaders = [...headers];
      newHeaders[resizingIndex].width = newWidth;
      setHeaders(newHeaders);
    }
  };

  const handleMouseUp = () => {
    setResizingIndex(null);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const scrollableNodeRef = useRef(null);
  const headersBox = useRef(null);

  function changeHeaderName(e) {
    let val = e.target.value;
    setChangeHeader(val);
  }

  return (
    <>
      <SimpleBar
        className={styles.tableContainer2}
        autoHide={false}
        onScrollCapture={onScroll}
        // style={{overflow: "auto"}}
        // forceVisible="y"
        // ref={scrollableNodeRef}
        scrollableNodeProps={{ ref: scrollableNodeRef }}
        onMouseMove={moveableColumns && handleMouseMove}
        style={style}
      >
        {data?.length === 0 && (
          <div className={styles.emptyMessage}>
            {emptyMessage ? emptyMessage : "No data to display"}
          </div>
        )}
        {data?.length > 0 && (
          <table className={styles.table}>
            <thead className={styles.header} ref={headersBox}>
              <tr>
                {select && (
                  <th
                    key={"#"}
                    style={{ fontSize: headerSize ? headerSize : "" }}
                  ></th>
                )}
                {rowNumbers && (
                  <th
                    key={"#"}
                    style={{
                      fontSize: headerSize ? headerSize : "",
                      width: "150px",
                      paddingLeft: ".5rem",
                    }}
                  >
                    #
                  </th>
                )}
                {rowNumbers && actionCell && (
                  <th
                    key={shortId()}
                    style={{ fontSize: headerSize ? headerSize : "" }}
                  >
                    Action
                  </th>
                )}
                {headers?.map((header, ind) => (
                  <React.Fragment key={ind}>
                    {header.enabled && (
                      <th
                        key={ind}
                        id={header.id}
                        className={`${header.sort && styles.sortedHeader} ${
                          headerSettings && styles.thSettings
                        } `}
                        style={{
                          fontSize: headerSize ? headerSize : "",
                          minWidth: header?.width ? header.width : "",
                          maxWidth: header?.width ? header.width : "",
                        }}

                        // onMouseUp={moveableColumns && handleMouseUp}
                      >
                        <div
                          className={`${styles.headerContainer} ${
                            headerSettings && styles.headerSettings
                          } ${
                            headerSettings &&
                            openHeader?.id === header?.id &&
                            styles.headerOpen
                          }`}
                          onClick={() =>
                            headerSettings
                              ? setOpenHeader(header)
                              : handleSetSort(
                                  header.accessor,
                                  ind,
                                  header.canSort
                                )
                          }
                        >
                          <div
                            className={styles.innerHeader}
                            // onClick={() =>
                            //   headerSettings
                            //     ? setOpenHeader(header?.id)
                            //     : handleSetSort(
                            //         header.accessor,
                            //         ind,
                            //         header.canSort
                            //       )
                            // }
                          >
                            <div className={styles.innerHeader2}>
                              {header?.name}
                            </div>
                            {header.canSort && (
                              <>
                                {header.sort &&
                                  (sort.descend ? (
                                    <i className="bi bi-arrow-down"></i>
                                  ) : (
                                    <i className="bi bi-arrow-up"></i>
                                  ))}
                                {!header.sort && <i className="bi bi-dash"></i>}
                              </>
                            )}
                          </div>

                          {/* {ind === headers.length - 1 && (
                          <div style={{ width: "200px" }}></div>
                        )} */}
                          {/* <FlexRow start style={{width: "fit-content"}}>
                       {header.settings &&  <div className={styles.columnOptions}><i className="bi-sliders"></i></div>} */}
                          <div
                            className={`${
                              moveableColumns ? styles.mover : styles.unmove
                            }`}
                            onMouseDown={(e) =>
                              moveableColumns &&
                              handleMouseDown(ind, e, header.id)
                            }
                          ></div>
                          {/* </FlexRow> */}
                        </div>

                        {openHeader?.id === header?.id && (
                          <div className={styles.settingspopup} ref={ref}>
                            <FlexRow gap=".5rem" start>
                              <input
                                value={
                                  changeHeader != false || changeHeader === ""
                                    ? changeHeader
                                    : header?.name
                                }
                                className={styles.changeInput}
                                onChange={changeHeaderName}
                              ></input>
                              {changeHeader && (
                                <Button blue shadow height={30}>
                                  Save
                                </Button>
                              )}
                            </FlexRow>
                            {salesforce && header?.salesforceColumn && (
                              <FlexRow start style={{ padding: "0 .5rem" }}>
                                <Icon iconName={"cloud"} blue></Icon>
                                <span className={styles.text_3}>
                                  {header.salesforceColumn}
                                </span>
                              </FlexRow>
                            )}
                            {salesforce && !header?.salesforceColumn && (
                              <FlexRow start style={{ padding: "0 .5rem" }}>
                                <Icon iconName={"cloud"} blue></Icon>
                                <span className={styles.text_3}>
                                  Not mapped to Salesforce
                                </span>
                              </FlexRow>
                            )}
                            <HorizontalBar
                              width={"100%"}
                              height="2px"
                              style={{ margin: "0rem" }}
                            ></HorizontalBar>
                            {header?.canSort && (
                              <>
                                <div
                                  className={styles.menu_item}
                                  style={{
                                    fontSize: ".8rem",
                                    padding: ".5rem 1rem",
                                  }}
                                  onClick={() =>
                                    handleSetSortSpecific(
                                      header.accessor,
                                      ind,
                                      header.canSort,
                                      false
                                    )
                                  }
                                >
                                  <i className={"bi-arrow-up"}></i> Sort
                                  Ascending
                                </div>
                                <div
                                  className={styles.menu_item}
                                  style={{
                                    fontSize: ".8rem",
                                    padding: ".5rem 1rem",
                                  }}
                                  onClick={() =>
                                    handleSetSortSpecific(
                                      header.accessor,
                                      ind,
                                      header.canSort,
                                      true
                                    )
                                  }
                                >
                                  <i className={"bi-arrow-down"}></i> Sort
                                  Descending
                                </div>
                              </>
                            )}
                            {addFilter && (
                              <div
                                className={styles.menu_item}
                                style={{
                                  fontSize: ".8rem",
                                  padding: ".5rem 1rem",
                                }}
                                onClick={() => addFilter(header)}
                              >
                                <i className={"bi-funnel"}></i> Add Filter
                              </div>
                            )}

                            {salesforce && (
                              <div
                                className={styles.menu_item}
                                style={{
                                  fontSize: ".8rem",
                                  padding: ".5rem 1rem",
                                }}
                                onClick={() => {
                                  setMapColumns(true);
                                }}
                              >
                                <i className={"bi-sliders"}></i> Edit Salesforce
                                Mapping
                              </div>
                            )}
                            <div
                              className={styles.menu_item}
                              style={{
                                fontSize: ".8rem",
                                padding: ".5rem 1rem",
                              }}
                              onClick={() => setAdjustFields(true)}
                            >
                              <i className={"bi-gear"}></i> Column Settings
                            </div>
                          </div>
                        )}
                      </th>
                    )}
                  </React.Fragment>
                ))}
                {/* <th
                key={"empty"}
                // className={ind % 2 === 0 ? styles.gray : ""}
                style={{
                  fontSize: headerSize ? headerSize : "",
                  minWidth: "100px",
                }}
              ></th> */}
              </tr>
            </thead>
            <tbody>
              {(paginateScroll ? topOfList : data)?.map((rowdata, ind) => (
                <tr key={ind} className={!editableFields ? styles.hovers : ""}>
                  {select && (
                    <td
                      key={"checkbox:" + ind}
                      style={{
                        fontSize: tableSize ? tableSize : "",
                        paddingTop: padding ? padding : "",
                        paddingBottom: padding ? padding : "",
                        paddingLeft: "2em",
                        overflow: overflowVisible ? "visible" : "",

                        // width: "50px",
                        // display: "flex",
                        // justifyContent: "center",
                        // alignItems: "center",
                      }}
                      className={`${ind % 2 === 0 && styles.gray}  ${
                        editableFields && styles.border
                      }`}
                    >
                      <Checkbox
                        checked={
                          selected && selected?.length > 0
                            ? selected.find((c) => c.id === rowdata?.id)
                            : false
                        }
                        onChange={(e) =>
                          changeSelected(rowdata, e.target.checked)
                        }
                        className={styles.check}
                      ></Checkbox>
                    </td>
                  )}
                  {rowNumbers && (
                    <td
                      key={"row num"}
                      style={{
                        fontSize: tableSize ? tableSize : "",
                        paddingTop: padding ? padding : "",
                        paddingBottom: padding ? padding : "",
                        overflow: overflowVisible ? "visible" : "",
                        paddingLeft: "1em",
                        width: "100px",
                      }}
                      className={`${ind % 2 === 0 && styles.gray}  ${
                        editableFields && styles.border
                      }`}
                    >
                      {ind + 1}
                    </td>
                  )}
                  {actionCell && (
                    <td
                      key={"action button"}
                      style={{
                        fontSize: tableSize ? tableSize : "",
                        paddingTop: padding ? padding : "",
                        paddingBottom: padding ? padding : "",
                        overflow: overflowVisible ? "visible" : "",
                        paddingLeft: "1em",
                      }}
                      className={`${ind % 2 === 0 && styles.gray}  ${
                        editableFields && styles.border
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickActionCell
                          ? onClickActionCell(rowdata, ind)
                          : "";
                      }}
                    >
                      {actionCellText}
                    </td>
                  )}

                  {headers?.map((head, i) => (
                    <React.Fragment key={i + head.id}>
                      {head.enabled && (
                        <td
                          onClick={(e) => {
                            if (head.onClick) {
                              head.onClick(rowdata);
                            } else if (onRowClick) {
                              onRowClick(rowdata);
                            }
                            if (onRowClickSelect) {
                              let selectedthis =
                                selected && selected?.length > 0
                                  ? selected.find((c) => c.id === rowdata?.id)
                                  : false;
                              changeSelected(rowdata, !selectedthis);
                            }
                          }}
                          style={{
                            fontSize: tableSize ? tableSize : "",
                            paddingTop: padding ? padding : "",
                            paddingBottom: padding ? padding : "",
                            padding:
                              window.innerWidth < 700
                                ? "20px 0px 20px 20px"
                                : "",
                            overflow: overflowVisible ? "visible" : "",

                            maxWidth: head?.width ? head.width : "",
                            paddingLeft:
                              editableFields && !head?.editable ? "5px" : "",
                            // paddingRight:
                            // width: head?.width ? head.width : "",
                          }}
                          className={`${ind % 2 === 0 && styles.gray}  ${
                            editableFields && styles.border
                          }`}
                        >
                          {(!editableFields || !head?.editable) && (
                            <>
                              {head?.obscure && <>...</>}

                              {!head?.obscure && (
                                <>
                                  {head?.objectAccessor &&
                                    head.cell_style &&
                                    screenForDate(
                                      rowdata[head.accessor]
                                        ? head.cell_style(
                                            rowdata[head.accessor][
                                              head.objectAccessor
                                            ],
                                            rowdata.id,
                                            i,
                                            rowdata
                                          )
                                        : null
                                    )}

                                  {head?.objectAccessor &&
                                    !head.cell_style &&
                                    screenForDate(
                                      rowdata[head.accessor]
                                        ? rowdata[head.accessor][
                                            head.objectAccessor
                                          ]
                                        : null
                                    )}
                                  {head?.cell_style &&
                                    !head?.objectAccessor &&
                                    screenForDate(
                                      head.cell_style(
                                        rowdata[head.accessor],
                                        rowdata.id,
                                        i,
                                        rowdata
                                      )
                                    )}
                                  {!head?.cell_style &&
                                    !head?.objectAccessor &&
                                    screenForDate(rowdata[head.accessor])}
                                  {!head?.accessor &&
                                    head.cell_style &&
                                    screenForDate(head.cell_style(rowdata, i))}
                                </>
                              )}
                            </>
                          )}

                          {editableFields && head?.editable && (
                            <>
                              {!head.cell_style && head?.objectAccessor && (
                                <InputField
                                  val={
                                    rowdata[head?.accessor][head.objectAccessor]
                                  }
                                  header={head}
                                  onChange={() =>
                                    onChangeEditableCell
                                      ? onChangeEditableCell(
                                          rowdata[head.accessor][
                                            head.objectAccessor
                                          ],
                                          ind
                                        )
                                      : ""
                                  }
                                  onBlur={() =>
                                    onSaveEditableCell
                                      ? onSaveEditableCell(
                                          rowdata[head.accessor][
                                            head.objectAccessor
                                          ],
                                          ind
                                        )
                                      : ""
                                  }
                                  rowdata={rowdata}
                                />
                              )}
                              {!head.cell_style && !head?.objectAccessor && (
                                <InputField
                                  val={rowdata[head?.accessor]}
                                  header={head}
                                  onChange={(newValue, header) =>
                                    onChangeEditableCell
                                      ? onChangeEditableCell(
                                          newValue,
                                          header,
                                          rowdata,
                                          ind
                                        )
                                      : ""
                                  }
                                  onBlur={(newValue, header) =>
                                    onSaveEditableCell
                                      ? onSaveEditableCell(
                                          newValue,
                                          header,
                                          rowdata,
                                          ind
                                        )
                                      : ""
                                  }
                                  rowdata={rowdata}
                                />
                              )}
                              {head.cell_style && head?.objectAccessor && (
                                <InputField
                                  val={head.cell_style(
                                    rowdata[head?.accessor][head.objectAccessor]
                                  )}
                                  header={head}
                                  onChange={() =>
                                    onChangeEditableCell
                                      ? onChangeEditableCell(
                                          rowdata[head.accessor][
                                            head.objectAccessor
                                          ],
                                          ind
                                        )
                                      : ""
                                  }
                                  onBlur={() =>
                                    onSaveEditableCell
                                      ? onSaveEditableCell(
                                          rowdata[head.accessor][
                                            head.objectAccessor
                                          ],
                                          ind
                                        )
                                      : ""
                                  }
                                  rowdata={rowdata}
                                />
                              )}
                              {head.cell_style && !head?.objectAccessor && (
                                <InputField
                                  val={head.cell_style(rowdata[head?.accessor])}
                                  header={head}
                                  onChange={(newValue, header) =>
                                    onChangeEditableCell
                                      ? onChangeEditableCell(
                                          newValue,
                                          header,
                                          rowdata,
                                          ind
                                        )
                                      : ""
                                  }
                                  onBlur={(newValue, header) =>
                                    onSaveEditableCell
                                      ? onSaveEditableCell(
                                          newValue,
                                          header,
                                          rowdata,
                                          ind
                                        )
                                      : ""
                                  }
                                  rowdata={rowdata}
                                />
                              )}
                            </>
                          )}
                        </td>
                      )}
                    </React.Fragment>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </SimpleBar>
      {adjustFields && (
        <FieldAdjuster
          show={adjustFields}
          setShow={setAdjustFields}
          orgId={organization?.id}
          user={user}
          salesforce={salesforce}
          startColumn={openHeader}
        ></FieldAdjuster>
      )}
      {salesforce && mapColumns && (
        <SalesforceMapping
          organization={organization}
          user={user}
          initheads={headers}
          customFields={customFields}
          showMapping={mapColumns}
          setShowMapping={setMapColumns}
        >
          {" "}
        </SalesforceMapping>
      )}
    </>
  );
}

// Example of the header format to pass into props
// const headers = [
//     {
//         id: 0,
//         name: "Project",
//         accessor: "project",
//         enabled: true,
//     },
//     {
//         id: 1,
//         name: "Status",
//         accessor: "status",
//         enabled: true,
//     },
//     {
//         id: 2,
//         name: "Responses",
//         accessor: "responses",
//         enabled: true,
//     },
//     {
//         id: 3,
//         name: "Owner",
//         accessor: "owner",
//         enabled: true,
//     },
//     {
//         id: 4,
//         name: "Created",
//         accessor: "created_at",
//         enabled: true,
//     },
//     {
//         id: 5,
//         name: "Modified",
//         accessor: "updated_at",
//         enabled: true,
//     },
// ]

function InputField({ val, header, onChange, rowdata, onBlur }) {
  const span = useRef(null);
  const [width, setWidth] = useState(0);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setWidth(span.current.offsetWidth + 20);
  }, []);

  function checkError() {
    if (
      rowdata?.flags[header.accessor] &&
      rowdata?.flags[header.accessor]?.length > 0
    ) {
      return "#FF8878";
    } else {
      ("");
    }
  }

  // console.log(val)
  return (
    <>
      {header.dataType === "text" && edit && (
        <TextFieldSimple
          value={val}
          customStyles={`${styles.textField}`}
          style={{
            width: width,
            color: rowdata?.flags ? checkError() : "",
            height: "100%",
          }}
          containerStyle={{ borderRadius: ".5em", height: "100%" }}
          autoFocus
          onChange={(value) => onChange(value, header)}
          onSave={(value) => onBlur(value, header)}
          editColor

          // noState
        />
      )}
      {header.dataType === "text" && !edit && (
        <div
          style={{
            width: width,
            color: rowdata?.flags ? checkError() : "",
            cursor: "text",
            paddingLeft: "5px",
          }}
          // autoFocus
          onClick={() => setEdit(true)}
          // noState
        >
          {val}
        </div>
      )}
      {header.dataType === "select" && (
        <CombinedInput
          value={val}
          style={{
            width: "100%",
            height: "100%",
            color: rowdata?.flags ? checkError() : "",
            padding: "0px",
            backgroundColor: "transparent",
            fontSize: "1.2em",
            borderRadius: ".5em",
          }}
          inputStyle={{ backgroundColor: "transparent" }}
          options={header?.options ? header.options : []}
          select
          onChange={(value) => onChange(value, header)}
          onBlur={(value) => onBlur(value, header)}
          anyWhereOptions
          search
          canCreate
          editColor
          noShadow
        />
      )}

      {header.dataType === "selectMultiple" && (
        <CombinedInput
          value={val}
          style={{
            // width: "100%",
            height: "100%",
            color: rowdata?.flags ? checkError() : "",
            paddingLeft: "5px",
            backgroundColor: "transparent",
            fontSize: "1.2em",
            borderRadius: ".5em",
          }}
          nowrap={header?.width ? false : true}
          options={header?.options ? header.options : []}
          selectMultiple
          onChange={(value) => onChange(value, header)}
          onBlur={(value) => onBlur(value, header)}
          anyWhereOptions
          canCreate
          editColor
          noShadow
          nohover
        />
      )}
      {header.dataType === "boolean" && (
        <CombinedInput
          value={
            val
              ? { value: true, label: "True" }
              : { value: false, label: "False" }
          }
          style={{
            width: "100%",
            height: "100%",
            color: rowdata?.flags ? checkError() : "",
            paddingLeft: "5px",
            backgroundColor: "transparent",
            fontSize: "1.2em",
            borderRadius: ".5em",
          }}
          options={[
            { value: true, label: "True" },
            { value: false, label: "False" },
          ]}
          select
          onChange={(value) => onChange(value, header)}
          onBlur={(value) => onBlur(value, header)}
          anyWhereOptions
          canCreate
          disableState
          editColor
          noShadow
        />
      )}
      <div className={styles.error}></div>
      <span
        ref={span}
        style={{ opacity: 0, position: "absolute", pointerEvents: "none" }}
      >
        {typeof val === "string" ? val : ""}
      </span>
    </>
  );
}
