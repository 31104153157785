import { useFetchPageProjects } from "api/resources/projects/projects";
import Filter from "../Charts/Settings/Filter/Filter";
import { Loading } from "components/Loading/Loading";
import { combinedProjIds, parsedViz } from "../Charts/Visualization";
import { MetricType, ValueType } from "../Charts/QuestionChart";
import { getScoreChartQueryData } from "../Charts/ScoreChart";
import { getStatChartQueryData } from "../Charts/StatChart/StatChart";

export function PageFilter({
  onFilterChange,
  chosenFilter,
  page,
  custom_fields,
}) {
  function getQueryData() {
    let data = {
      projectIds: [],
      dynamics: [],
    };

    for (let visualization of page.visualization) {
      let viz = parsedViz(visualization);
      let supplement;
      if (viz.type === MetricType || viz.type === ValueType) {
        supplement = getScoreChartQueryData(viz);
      } else if (viz.dataMethod === "survey") {
        supplement = getStatChartQueryData(viz);
      } else {
        data.projectIds = [...data.projectIds, ...combinedProjIds(viz)];
      }

      if (supplement) {
        data.projectIds = [...data.projectIds, ...supplement.projectIds];
        data.dynamics = [...data.dynamics, ...supplement.dynamics];
      }
    }

    return data;
  }

  const getProjects = useFetchPageProjects(getQueryData());

  return (
    <>
      {getProjects.isError && <div>Error</div>}
      {getProjects.isLoading && <Loading />}
      {getProjects.isSuccess && (
        <Filter
          custom_fields={custom_fields}
          updateChosenFilters={onFilterChange}
          chosenFilter={chosenFilter}
          projects={getProjects.data.survey}
        ></Filter>
      )}
    </>
  );
}
