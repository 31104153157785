import { useEffect, useState } from "react";
// import AccordionItem from "./Settings/AccordionItem";
import { DataLabels } from "./AccordianItems/DataLabels";
import { LegendSettings } from "./AccordianItems/LegendSettings";
import { TitleSettings } from "./AccordianItems/TitleSettings";
import { Axes } from "./AccordianItems/Axes";
import styles from "./SettingsAccordion.module.scss";

import {
  ValueType,
  TableType,
  MetricType,
  defaultChartSettings,
  VertBarType,
  splitSomehow,
  LineType,
  HorizBarType,
} from "../QuestionChart";
import { SplitsNPivotsAccordion } from "./AccordianItems/SplitsNPivots";
import { FilterAccordian } from "./AccordianItems/FilterSettings";
import { SortingSettings } from "./AccordianItems/SortingSettings";
import { TableColumns } from "./AccordianItems/TableColumns";
import { TextBoxSettings } from "./AccordianItems/TextBoxSettings";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import { ChartType } from "./AccordianItems/ChartType";
import { ColorSettings } from "./AccordianItems/ColorSettings";
import { AdvancedSettings } from "./AccordianItems/AdvancedSettings";
import Table from "../Tables/Table";
import { useGetFilterableAnswers } from "api/resources/projects/answers";
import { useFetchProjectsByIdsGql } from "api/resources/projects/projects";
import TableChart from "components/Charts/Table/TableChart";
import { Loading } from "components/Loading Rectangle/Loading";
import { LayoutSettings } from "./AccordianItems/LayoutSettings";
import { DataSettings } from "./AccordianItems/DataSettings";
import MetricSettings from "./AccordianItems/MetricSettings/MetricSettings";
import { combineFilters, combinedProjIds, combinedQs } from "../Visualization";
import {
  TablePivotsAccordian,
  TablePivotsModal,
} from "./AccordianItems/TablePivots";

import { StatSplitsNPivotsAccordian } from "./AccordianItems/StatSplitsNPivots";

import { AdvancedStatSettings } from "./AccordianItems/AdvancedStatSettings";

export function splitting(viz) {
  if (viz.dataMethod === "survey") {
    return (
      viz.pivotString ||
      viz.designSettings.split ||
      viz.designSettings.useComparison
    );
  } else {
    return splitSomehow(viz) || viz.designSettings.useComparison;
  }
}

/**
 *
 * @param {object} viz the visualization object
 * @param {function} updateSettings function to change the design settings
 * @param {function} updateType function to change the type of the chart
 * @param {function} updateTitle function to change the visualization title
 * @param {function} changeData fiunction to change the
 * @returns
 */

export const SettingsAccordion = ({
  viz,
  updateViz,
  labels,
  lockData,
  projects,
  custom_fields,
  onFilterChange,
  newChart,
  dataPage,
  setDataPage,
  externalFilter,
  hasData,
  visibleItems,
  setVisible,
}) => {
  // const getRole = useFetchQChartRoles();

  const scoreType = viz.type === MetricType || viz.type === ValueType;

  const changeSettingsField = (field, val) => {
    let newViz = { ...viz };
    newViz.designSettings[field] = val;
    updateViz(newViz);
  };

  const changeVizField = (field, val) => {
    let newViz = { ...viz };
    newViz[field] = val;
    updateViz(newViz);
  };

  const [originalColors, setOriginalColors] = useState(
    viz.designSettings.colors
      ? {
          colors: viz.designSettings.colors,
          split: viz.designSettings.split,
          pivot: viz.pivotString,
          filters: viz.filters,
        }
      : undefined
  );

  function changePage(dPage) {
    setDataPage(dPage);
  }

  function toggle(val) {
    visibleItems === val ? setVisible("") : setVisible(val);
  }

  return (
    <>
      {!scoreType && (
        <div className={styles.navContainer}>
          <div className={styles.nav} style={{ flexDirection: "column" }}>
            <div className={styles.nav} style={{ justifyContent: "end" }}>
              <div className={styles.nav2}>
                <div
                  className={`${styles.navItem} ${
                    dataPage ? styles.active : ""
                  }`}
                  onClick={() => changePage(true)}
                >
                  <i className="bi-receipt-cutoff"></i>Data
                </div>
                <HorizontalBar width={5} height={25} rotate />
                <div
                  className={`${styles.navItem} ${
                    !dataPage ? styles.active : ""
                  }`}
                  onClick={hasData ? () => changePage(false) : undefined}
                >
                  <i className="bi-brush"></i>Style
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
                maxWidth: "315px",
                justifyContent: "space-around",
              }}
            >
              {dataPage && (
                <>
                  <HorizontalBar
                    height={3}
                    width={"27%"}
                    backgroundColor={"#15bcc7"}
                    rotate
                  />
                  <HorizontalBar
                    height={3}
                    width={"32%"}
                    backgroundColor={"transparent"}
                    rotate
                  />
                </>
              )}{" "}
              {!dataPage && (
                <>
                  <HorizontalBar
                    height={3}
                    width={"32%"}
                    backgroundColor={"transparent"}
                    rotate
                  />
                  <HorizontalBar
                    height={3}
                    width={"27%"}
                    backgroundColor={"#15bcc7"}
                    rotate
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <div className={styles.accordion}>
        {dataPage && (
          <>
            <DataSettings // columns
              viz={viz}
              title="Data"
              visible={visibleItems === "Data"}
              setVisible={() => toggle("Data")}
              changeSettingsField={changeSettingsField}
              updateViz={updateViz}
              projects={projects}
              newChart={newChart}
              lockData={lockData}
            />
            <FilterAccordian // columns
              viz={viz}
              projects={projects}
              visible={visibleItems === "FilterAccordian"}
              setVisible={() => toggle("FilterAccordian")}
              custom_fields={custom_fields}
              onFilterChange={onFilterChange}
              changeSettingsField={changeSettingsField}
              externalFilter={externalFilter}
            />
            {viz.dataMethod === "survey" && (
              <>
                <StatSplitsNPivotsAccordian
                  viz={viz}
                  updateViz={updateViz}
                  projects={projects}
                  custom_fields={custom_fields}
                  originalColors={originalColors}
                  visible={visibleItems === "SplitsNPivots"}
                  setVisible={() => toggle("SplitsNPivots")}
                ></StatSplitsNPivotsAccordian>
                
                {hasData && (
                  <AdvancedStatSettings
                    viz={viz}
                    updateViz={updateViz}
                    changeSettingsField={changeSettingsField}
                    projects={projects}
                    visible={visibleItems === "AdvancedSettings"}
                    setVisible={() => toggle("AdvancedSettings")}
                  ></AdvancedStatSettings>
                )}
              </>
            )}
            {viz.dataMethod !== "survey" && (
              <>
                {viz.type === TableType ? (
                  <TablePivotsAccordian
                    viz={viz}
                    updateViz={updateViz}
                    changeSettingsField={changeSettingsField}
                    custom_fields={custom_fields}
                    projects={projects}
                    visible={visibleItems === "SplitsNPivots"}
                    setVisible={() => toggle("SplitsNPivots")}
                  />
                ) : (
                  <SplitsNPivotsAccordion
                    viz={viz}
                    visible={visibleItems === "SplitsNPivots"}
                    setVisible={() => toggle("SplitsNPivots")}
                    changeSettingsField={changeSettingsField}
                    updateViz={updateViz}
                    projects={projects}
                    custom_fields={custom_fields}
                  />
                )}
                <AdvancedSettings
                  viz={viz}
                  updateViz={updateViz}
                  visible={visibleItems === "AdvancedSettings"}
                  setVisible={() => toggle("AdvancedSettings")}
                  filters={combineFilters(viz, externalFilter)}
                  changeSettingsField={changeSettingsField}
                />
              </>
            )}

            {/* {dataPage && (
                            <div className={styles.dataHeader}>
                                <div style={{ fontSize: ".8em" }}>
                                    <SelectField
                                        onChange={(data) => changeMethod(data)}
                                        options={dataMethods}
                                        value={dataMethod}
                                        placeholder="Choose how to get your data"
                                        height={28}
                                        disable={viz.type === TableType}
                                    ></SelectField>
                                </div>
                                {hasData && (
                                    <div className={styles.settings}>
                                        <div
                                            className={
                                                styles.reportSettingsButton
                                            }
                                            onClick={() =>
                                                setVisible("Filters")
                                            }
                                        >
                                            {" "}
                                            <i className="bi-funnel"></i>Filters
                                            {filterCount > 0 && (
                                                <div className={styles.number}>
                                                    {getFilterCount()}
                                                </div>
                                            )}
                                        </div>{" "}
                                        <HorizontalBar
                                            rotate
                                            height={15}
                                            width={2}
                                        ></HorizontalBar>
                                        <div
                                            className={
                                                styles.reportSettingsButton
                                            }
                                            onClick={() =>
                                                setVisible("SplitsNPivots")
                                            }
                                        >
                                            <i className="bi-person-lines-fill"></i>
                                            Splits & Pivots{" "}
                                            {getPivotCount() > 0 && (
                                                <div className={styles.number}>
                                                    {getPivotCount()}
                                                </div>
                                            )}
                                        </div>
                                        {visibleItems === "SplitsNPivots" && (
                                            <div
                                                className={
                                                    styles.settingsPopout
                                                }
                                            >
                                                {viz.type !== TableType && (
                                                    <>
                                                        {viz.dataMethod ===
                                                            "survey" && (
                                                            <StatSplitsNPivotsModal // list-check    eyeglasses    graph-up     list-columns   list-nested   list-ul    person-check-fill   person-gear  person-lines-fill    person-plus-fill   sliders-2
                                                                viz={viz}
                                                                updateViz={
                                                                    updateViz
                                                                }
                                                                projects={
                                                                    projects
                                                                }
                                                                close={() =>
                                                                    setVisible(
                                                                        ""
                                                                    )
                                                                }
                                                                changeSettingsField={
                                                                    changeSettingsField
                                                                }
                                                                custom_fields={
                                                                    custom_fields
                                                                }
                                                                originalColors={
                                                                    originalColors
                                                                }
                                                            ></StatSplitsNPivotsModal>
                                                        )}

                                                        {viz.dataMethod !==
                                                            "survey" && (
                                                            <SplitsNPivotsModal // list-check    eyeglasses    graph-up     list-columns   list-nested   list-ul    person-check-fill   person-gear  person-lines-fill    person-plus-fill   sliders-2
                                                                viz={viz}
                                                                updateViz={
                                                                    updateViz
                                                                }
                                                                close={() =>
                                                                    setVisible(
                                                                        ""
                                                                    )
                                                                }
                                                                changeSettingsField={
                                                                    changeSettingsField
                                                                }
                                                                custom_fields={
                                                                    custom_fields
                                                                }
                                                                originalColors={
                                                                    originalColors
                                                                }
                                                            ></SplitsNPivotsModal>
                                                        )}
                                                    </>
                                                )}
                                                {viz.type === TableType && (
                                                    <TablePivotsModal
                                                        viz={viz}
                                                        updateViz={updateViz}
                                                        close={() =>
                                                            setVisible("")
                                                        }
                                                        changeSettingsField={
                                                            changeSettingsField
                                                        }
                                                        custom_fields={
                                                            custom_fields
                                                        }
                                                        projects={projects}
                                                    ></TablePivotsModal>
                                                )}
                                            </div>
                                        )}
                                        {visibleItems === "Filters" && (
                                            <div
                                                className={
                                                    styles.settingsPopout
                                                }
                                            >
                                                <FilterModal // funnel-fill      person-lines-fill
                                                    viz={viz}
                                                    close={() => setVisible("")}
                                                    projects={projects}
                                                    custom_fields={
                                                        custom_fields
                                                    }
                                                    onFilterChange={
                                                        onFilterChange
                                                    }
                                                    changeSettingsField={
                                                        changeSettingsField
                                                    }
                                                    externalFilter={
                                                        externalFilter
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )} */}
            {/* {dataMethod.value === "question" && (
                            <>
                                {newChart &&
                                    !combinedQs(viz).length &&
                                    noEmptyQTagSelections() && (
                                        <div className={styles.switchType}>
                                            <div
                                                className={
                                                    styles.selectContainer
                                                }
                                            >
                                                <CombinedInput
                                                    value={getCurrType()}
                                                    options={typeVals}
                                                    style={{
                                                        backgroundColor:
                                                            "#ffffff",
                                                    }}
                                                    hideChosen
                                                    select
                                                    label={"Type"}
                                                    icon={
                                                        "bi bi-chevron-compact-down"
                                                    }
                                                    onChange={onChangeType}
                                                />
                                            </div>
                                        </div>
                                    )}

                                <div className={styles.dataSettings}>
                                    {!lockData && (
                                        <QData // clipboard-data      journal-text
                                            viz={viz}
                                            updateViz={updateViz}
                                            newChart={newChart}
                                        ></QData>
                                    )}

                                    <HorizontalBar
                                        width={3}
                                        height={"100%"}
                                        rotate
                                    />

                                    {hasData && (
                                        <AdvancedSettings
                                            viz={viz}
                                            updateViz={updateViz}
                                            filters={combineFilters(
                                                viz,
                                                externalFilter
                                            )}
                                            changeSettingsField={
                                                changeSettingsField
                                            }
                                        ></AdvancedSettings>
                                    )}
                                </div>
                                <HorizontalBar
                                    width={"96%"}
                                    height={2}
                                    style={{ margin: "0em" }}
                                />
                            </>
                        )}{" "} */}
          </>
        )}
        {!dataPage && (
          <>
            {viz.type === TableType && hasData && (
              <TableColumns // layout-text-window-reverse
                viz={viz}
                title="Table Columns"
                changeSettingsField={changeSettingsField}
                updateViz={updateViz}
                visible={visibleItems === "Table"}
                setVisible={() => toggle("Table")}
                projects={projects}
                custom_fields={custom_fields}
              ></TableColumns>
            )}

            {!scoreType && viz.type !== TableType && (
              <ChartType // pie-chart
                viz={viz}
                title="Chart Type"
                updateViz={updateViz}
                visible={visibleItems === "ChartType"}
                setVisible={() => toggle("ChartType")}
              />
            )}

            {scoreType && (
              <>
                <MetricSettings
                  title="Metrics"
                  changeSettings={changeSettingsField}
                  updateViz={updateViz}
                  settings={viz.designSettings}
                  viz={viz}
                  visible={visibleItems === "MetricSettings"}
                  setVisible={() => toggle("MetricSettings")}
                />
                <FilterAccordian // funnel-fill      person-lines-fill
                  viz={viz}
                  visible={visibleItems === "Filters"}
                  setVisible={() => toggle("Filters")}
                  projects={projects}
                  custom_fields={custom_fields}
                  onFilterChange={onFilterChange}
                  changeSettingsField={changeSettingsField}
                  externalFilter={externalFilter}
                />
              </>
            )}

            <LayoutSettings // columns
              viz={viz}
              title="Layout"
              visible={visibleItems === "LayoutSettings"}
              setVisible={() => toggle("LayoutSettings")}
              changeSettingsField={changeSettingsField}
              projects={projects}
            />

            {!scoreType && (
              <ColorSettings // bar-chart-fill
                viz={viz}
                title="Data Points"
                changeSettingsField={changeSettingsField}
                labels={labels}
                updateViz={updateViz}
                visible={visibleItems === "ChartSegments"}
                setVisible={() => toggle("ChartSegments")}
              />
            )}
            {!scoreType && (
              <>
                {(viz.type === VertBarType ||
                  viz.type === HorizBarType ||
                  viz.type === LineType) && (
                  <Axes // graph-up
                    viz={viz}
                    title="Axes"
                    changeSettingsField={changeSettingsField}
                    updateViz={updateViz}
                    visible={visibleItems === "Axes"}
                    setVisible={() => toggle("Axes")}
                    labels={labels}
                  />
                )}
              </>
            )}

            {/* {scoreType && hasData && (
              <ScoreSettings
                viz={viz}
                title="Value Settings"
                updateViz={updateViz}
                changeSettingsField={changeSettingsField}
                visible={visibleItems["ScoreData"]}
                setVisible={() => setVisible("ScoreData")}
              />
            )} */}

            {hasData && (
              <>
                {!scoreType && (
                  <TitleSettings //input-cursor-text    input cursor
                    viz={viz}
                    title="Title" //"Chart
                    changeVizField={changeVizField}
                    changeSettingsField={changeSettingsField}
                    visible={visibleItems === "Title"}
                    setVisible={() => toggle("Title")}
                    updateViz={updateViz}
                  ></TitleSettings>
                )}

                {!scoreType && viz.type !== TableType && (
                  <>
                    <LegendSettings // list-ul    map     menu-app-fill
                      viz={viz}
                      title="Legend"
                      changeSettingsField={changeSettingsField}
                      visible={visibleItems === "Legend"}
                      setVisible={() => toggle("Legend")}
                      labels={labels}
                    ></LegendSettings>
                    <DataLabels // 2-circle-fill    superscript
                      viz={viz}
                      title="Data Labels"
                      labels={labels}
                      changeSettingsField={changeSettingsField}
                      changeVizField={changeVizField}
                      visible={visibleItems === "Labels"}
                      setVisible={() => toggle("Labels")}
                    ></DataLabels>

                    <SortingSettings // sort-alpha-down    or     sort-down
                      viz={viz}
                      title="Sorting"
                      changeSettingsField={changeSettingsField}
                      labels={labels}
                      visible={visibleItems === "SortingSettings"}
                      setVisible={() => toggle("SortingSettings")}
                    ></SortingSettings>
                  </>
                )}
              </>
            )}
            <TextBoxSettings // sort-alpha-down    or     sort-down
              viz={viz}
              title="Text Boxes"
              changeSettingsField={changeSettingsField}
              labels={labels}
              visible={visibleItems === "TextBoxSettings"}
              setVisible={() => toggle("TextBoxSettings")}
            ></TextBoxSettings>
          </>
        )}
      </div>
    </>
  );
};

function ManualDataTable({ viz }) {
  const [data, setData] = useState(getData(viz.data));

  function getData(data) {
    let tempData = data;
    while (typeof tempData === "string") {
      tempData = JSON.parse(tempData);
    }

    return tempData;
  }

  return (
    <>
      <TableChart manual chartData={data} spreadsheetmode />
    </>
  );
}

function SurveyDataTables({
  viz,
  custom_fields,
  chartData,
  setChartData,
  filters,
}) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  function getUserConstraints() {
    return viz.designSettings?.dynamic
      ? viz.designSettings.userConstraints
      : undefined;
  }

  const fetchAnswers = useGetFilterableAnswers(
    combinedQs(viz).map((q) => q.id),
    filters,
    getUserConstraints(),
    setData,
    ""
  );

  const vizProjIds = combinedProjIds(viz);

  const getProjects = useFetchProjectsByIdsGql(
    {
      projectIds: vizProjIds,
    },
    viz.id
  );

  // useEffect(() => {
  //   fetchAnswers.refetch();
  //   getProjects.refetch();

  // }, [qIds, filters]);

  // useEffect(() => {
  //   if (loading) {
  //     setLoading(false);
  //   }
  // }, [loading]);

  return (
    <>
      {(fetchAnswers.isLoading ||
        getProjects.isLoading ||
        fetchAnswers.isRefetching ||
        getProjects.isRefetching) && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Loading></Loading>
        </div>
      )}
      {fetchAnswers.isSuccess &&
        getProjects.isSuccess &&
        !fetchAnswers.isRefetching &&
        !getProjects.isRefetching &&
        fetchAnswers.data?.answers?.length > 0 && (
          <Table
            answers={fetchAnswers.data?.answers}
            filters={filters}
            // onClose={closeTablePopup}
            viz={viz}
            projects={getProjects.data?.survey}
            // surveyTags={getSurveyTags.data.tags}
            canSeeContactInfo
            //canSeeContactInfo={currRole.data.role.canSeeContactInfo}
            // drill={drill}
            // titleStyle={getStyle()}
            custom_fields={custom_fields}
            // spreadsheetmode
            togglespreadsheet
            chartData={chartData}
            setChartData={setChartData}
            editMode
          />
        )}
    </>
  );
}
