import React, { useState } from "react";
import styles from "./SalesforceSettings.module.scss";
import {
  useFetchOrganizationLinkedAccounts,
  useFetchOrgFieldsNoProps,
  useUpdateOrgSettings,
} from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import Button from "components/Button/Button";
import Modal from "components/ReactModal/ReactModal";
import { SelectFieldCustom, TextFieldSimple } from "components/inputs";
import { deleteLinkedAccount } from "api/resources/contacts/contacts";
import { CheckSyncContacts } from "pages/contacts/all_contacts/ContactsSettings";
import Icon from "components/Icon/Icon";
import ExternalImport from "pages/contacts/ExternalImport/ExternalImport";

function SalesforceSettings({ roles, user }) {
  const { id } = useParams();
  const MyOrg = useFetchOrganizationLinkedAccounts(id);
  return (
    <div className={styles.page}>
      {MyOrg.isLoading && <Loading></Loading>}
      {MyOrg.isSuccess && (
        <SalesforceSettingsContent
          org={MyOrg.data.organization}
          role={roles}
          user={user}
          refetch={MyOrg.refetch}
        />
      )}
    </div>
  );
}

export default SalesforceSettings;

const providers = [
  {
    value: "Salesforce",
    label: (
      <div
        style={{
          display: "flex",
          gap: ".5rem",
          alignItems: "center",
          width: "150px",
        }}
      >
        <img
          src={require("assets/images/Salesforce.png")}
          style={{ width: "50px" }}
        ></img>
        Salesforce
      </div>
    ),
  },
  // {
  //   value: "HubSpot",
  //   label: (
  //     <div
  //       style={{
  //         display: "flex",
  //         gap: ".5rem",
  //         alignItems: "center",
  //         width: "150px",
  //       }}
  //     >
  //       <img
  //         src={require("assets/images/hubspot.webp")}
  //         style={{ width: "50px" }}
  //       ></img>
  //       HubSpot
  //     </div>
  //   ),
  // },
];

function SalesforceSettingsContent({ org, role, user, refetch }) {
  const [organization, setOrganization] = useState(org);
  const [clientId, setClientId] = useState(organization?.sfClientId);
  const [clientSecret, setClientSecret] = useState(organization?.sfClientId);
  const [BtnMsg, setBtnMsg] = useState("Save Changes");
  const [edit, setEdit] = useState(false);
  const [edited, setEdited] = useState(false);
  const [syncType, setSyncType] = useState(organization.sfSyncType);
  const [syncPriority, setSyncPriority] = useState(
    organization.sfSyncPriority ? organization.sfSyncPriority : "reaction"
  );
  const [process, setProcess] = useState(false);
  const [BtnMsg2, setBtnMsg2] = useState("Start Sync Process");
  const [showLinking, setShowLinking] = useState("");

  // const objects = [
  //   { label: "Contacts", value: "contacts" },
  //   { label: "Accounts", value: "Accounts" },
  // ];

  const objects = [
    { label: "One Way Reaction", value: "reaction" },
    { label: "One Way Salesforce", value: "salesforce" },
    { label: "Two Way Synchronization", value: "two-way" },
  ];

  const priority = [
    { label: "Reaction Contacts", value: "reaction" },
    { label: "Salesforce Contacts", value: "salesforce" },
  ];

  const deleteLink = deleteLinkedAccount();
  const navigate = useNavigate();
  function removeLinkedAccount(id) {
    deleteLink.mutate(
      {
        id: id,
      },
      {
        onSuccess: () => {
          navigate("/organization/" + organization.id + "/contact-management");
        },
      }
    );
  }

  const updateOrg = useUpdateOrgSettings();

  function handleSave() {
    if (role?.canUpdateOrganization) {
      setBtnMsg(
        <div style={{ display: "flex", gap: "5px" }}>
          Saving <Loading height={20} width={20}></Loading>
        </div>
      );
      updateOrg.mutate(
        {
          updateOrganizationId: organization.id,
          data: {
            sfClientId: organization.sfClientId,
            sfClientSecret: organization.sfClientSecret,
          },
        },
        {
          onSuccess: (data) => {
            // console.log("Updated Org: ", data);
            setBtnMsg(
              <div style={{ display: "flex", gap: "5px" }}>
                Saved <i className="bi bi-check-lg"></i>
              </div>
            );
            setTimeout(() => {
              location.reload();
            }, 750);
          },
        }
      );
    }
  }

  function handleCancel() {
    setOrganization({ ...organization });
    setEdited(false);
    setEdit(false);
  }

  function onChange(val, field) {
    let edits = { ...organization };
    edits[field] = val;
    setOrganization(edits);
    setEdited(true);
  }

  // const fetchCustomFields = useFetchOrgFieldsNoProps(organization?.id);

  return (
    <>
      {" "}
      <Modal
        show={edit}
        onClose={() => setEdit(false)}
        rightStyle
        dark
        modalStyle={{
          width: "500px",
          height: "100%",
          padding: "1rem",
          gap: "1rem",
        }}
      >
        <div className={styles.header_3} style={{ fontWeight: "400" }}>
          Edit Salesforce Settings
        </div>
        <Button
          red
          shadow
          onClick={() =>
            removeLinkedAccount(
              organization?.linkedAccounts?.find(
                (l) => l.accountType === "Salesforce"
              )?.id
            )
          }
          style={{ width: "fit-content" }}
        >
          Disconnect Account
        </Button>
        <SelectFieldCustom
          value={objects.find((o) => o.value === organization.sfSyncType)}
          label="Synchronization Type"
          shadow
          options={objects}
          select
          onChange={(val) => onChange(val.value, "sfSyncType")}
          style={{ backgroundColor: "white" }}
          onClick={() => setShowLinking(true)}
        />

        <TextFieldSimple
          value={clientId}
          label="Consumer Key"
          shadow
          style={{ backgroundColor: "white" }}
          onChange={(val) => onChange(val, "sfClientId")}
          type="password"
        />
        <TextFieldSimple
          value={clientSecret}
          label="Consumer Secret"
          shadow
          style={{ backgroundColor: "white" }}
          onChange={(val) => onChange(val, "sfClientSecret")}
          type="password"
        />

        <div
          style={{
            display: "flex",
            gap: "1em",
            paddingTop: ".5em",
            justifyContent: "flex-end",
          }}
        >
          <Button red height={35} onClick={handleCancel} shadow link>
            Cancel
          </Button>
          <Button
            height={35}
            onClick={handleSave}
            shadow
            blue
            disable={!edited}
          >
            {BtnMsg}
          </Button>
        </div>
      </Modal>
      <div className={styles.section}>
        <FlexRow>
          <div className={styles.header_3} style={{ fontWeight: "400" }}>
            Salesforce Settings
          </div>
          <Button shadow style={{ gap: ".5rem" }} onClick={() => setEdit(true)}>
            <i className="bi-pencil"></i> Edit
          </Button>
        </FlexRow>
        <div className={styles.infoTable}>
          <div className={`${styles.infoRow} ${styles.gray} ${styles.top}`}>
            <span>Connected Account</span>
            <span>{organization.sfConnectedAccount}</span>
          </div>
          <div className={`${styles.infoRow} ${styles.dark}`}>
            <span>Synchronization Type</span>
            <span>
              {objects.find((o) => o.value === organization.sfSyncType).label}
            </span>
          </div>
          {/* <div className={`${styles.infoRow} ${styles.gray}`}>
            <span>Contact Priority</span>
            <span>
              {
                priority.find((o) => o.value === organization.sfSyncPriority)
                  .label
              }
            </span>
          </div> */}
          <div className={`${styles.infoRow} ${styles.gray}`}>
            <span>Consumer Key</span>
            <span>
              *****
              {organization.sfClientSecret && organization.sfClientId.slice(-4)}
            </span>
          </div>
          <div className={`${styles.infoRow} ${styles.dark} ${styles.bottom}`}>
            <span>Consumer Secret</span>
            <span>
              *****
              {organization.sfClientSecret &&
                organization.sfClientSecret.slice(-4)}
            </span>
          </div>
        </div>
      </div>
      <span className={styles.text_2} style={{ textAlign: "center" }}>
        You have <CheckSyncContacts organizationId={organization.id} /> Contacts
        not synced with Salesforce.
        <br />
        Click{" "}
        <a
          className={styles.link}
          onClick={() => navigate("/import-contacts/salesforce")}
        >
          Here
        </a>{" "}
        to begin the process.
      </span>
      <Modal
        show={showLinking}
        onClose={() => setShowLinking(false)}
        // rightStyle
        dark
        modalStyle={{
          // width: "500px",
          // height: "100%",
          // padding: "1.5rem 1rem",
          gap: "1rem",
          borderRadius: "1rem",
        }}
      >
        <div className={styles.page1}>
          {" "}
          <div className={styles.header_3} style={{ margin: "0" }}>
            How would you like to sync your contacts?
          </div>
          <div
            className={`${styles.option} ${
              syncType === "reaction" && styles.active2
            }`}
            onClick={() => setSyncType("reaction")}
          >
            <div className={styles.header_4} style={{ margin: "0" }}>
              One Way Reaction
            </div>
            <img
              src={require("assets/images/reactiontosalesforce.png")}
              width={"200px"}
            ></img>
            <span className={styles.text_2}>
              Make Reaction the source of truth. Changes in Reaction are updated
              to Salesforce, but updates in Salesforce are not pushed to
              Reaction.
            </span>
            {syncType === "reaction" && (
              <div style={{ position: "absolute", right: "1rem", top: "40%" }}>
                <Icon iconName="check" blue style={{ fontSize: "1.5rem" }} />
              </div>
            )}
          </div>
          <div
            className={`${styles.option} ${
              syncType === "salesforce" && styles.active2
            }`}
            onClick={() => setSyncType("salesforce")}
          >
            <div className={styles.header_4} style={{ margin: "0" }}>
              One Way Salesforce
            </div>
            <img
              src={require("assets/images/salesforcetoreaction.png")}
              width={"200px"}
            ></img>
            <span className={styles.text_2}>
              Make Salesforce the source of truth. Changes in Salesforce are
              updated to Reaction, but updates in Reaction are not pushed to
              Salesforce.
            </span>
            {syncType === "salesforce" && (
              <div style={{ position: "absolute", right: "1rem", top: "40%" }}>
                <Icon iconName="check" blue style={{ fontSize: "1.5rem" }} />
              </div>
            )}
          </div>
          <div
            className={`${styles.option} ${
              syncType === "two-way" && styles.active2
            }`}
            onClick={() => setSyncType("two-way")}
          >
            <div className={styles.header_4} style={{ margin: "0" }}>
              Two Way Synchronization
            </div>
            <img
              src={require("assets/images/twowayreactionsalesforce.png")}
              width={"200px"}
            ></img>
            <span className={styles.text_2}>
              Both Salesforce and Reaction Contacts will both stay up to date at
              the same time. Changes in Salesforce are updated to Reaction and
              changes in Reaction are updated to Salesforce.
            </span>
            {syncType === "two-way" && (
              <div style={{ position: "absolute", right: "1rem", top: "40%" }}>
                <Icon iconName="check" blue style={{ fontSize: "1.5rem" }} />
              </div>
            )}
          </div>
          {/* {syncType === "two-way" && (
            <SelectFieldCustom
              value={priority.find(
                (o) => o.value === organization.sfSyncPriority
              )}
              label="Contact Priority"
              shadow
              options={priority}
              select
              onChange={(val) => onChange(val.value, "sfSyncPriority")}
              style={{ backgroundColor: "white" }}
            />
          )} */}
          {/* <span className={styles.text_2}>
          Click "Start Sync Process" to start the initial merge of your Reaction
          & Salesforce contacts.
        </span> */}
        </div>
      </Modal>
    </>
  );
}
