import { useFetchSurveyTags } from "api/resources/organization/surveytags";
import { combinedProjIds, combinedQs } from "../../Visualization";
import styles from "../SettingsAccordion.module.scss";
import { useRef, useEffect, useState } from "react";
import { Label } from "components/layouts/Label/Label";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import {
  AnswerCount,
  AvgScore,
  NpsScore,
  ParticipationCount,
} from "../../QuestionChart";
import { PivotTableFieldsCreator } from "../../Tables/PivotTableFieldsCreator";
import { createPivotColumnsForACombinationOfQs } from "../../TableViz";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";

export function TablePivotsModal({
  viz,
  updateViz,
  close,
  changeSettingsField,
  projects,
  custom_fields,
}) {
  const ref = useRef();

  function clickOut(e) {
    if (ref.current && !ref.current.contains(e.target)) {
      close();
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOut, true);
    return () => {
      document.removeEventListener("click", clickOut, true);
    };
  }, []);

  return (
    <div
      className={styles.body}
      style={{ borderRadius: "1em", minHeight: "200px" }}
      ref={ref}
    >
      <div
        key={viz.id}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div key={"filters"} className={`${styles.headerStatic}`}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1em",
              padding: "0rem 1rem",
            }}
          >
            Table Pivots{" "}
            <i
              style={{ fontSize: "1.2em" }}
              className="bi-person-list-fill"
            ></i>
          </div>
        </div>
        <span className={styles.xicon} onClick={close}>
          <i className="bi-x-lg"></i>
        </span>
      </div>

      <TablePivots
        viz={viz}
        updateViz={updateViz}
        changeSettingsField={changeSettingsField}
        projects={projects}
        custom_fields={custom_fields}
      />
    </div>
  );
}

export function TablePivotsAccordian({
  visible,
  setVisible,
  viz,
  updateViz,
  changeSettingsField,
  projects,
  custom_fields,
}) {
  function getPivotCount() {
    let count = 0;
    if (viz.pivotString) {
      count += 1;
    }
    if (viz.designSettings.split) {
      count += 1;
    }
    return count;
  }

  return (
    <div
      key={viz.id}
      className={`${styles.header} ${visible ? styles.headervisible : ""}`}
      onClick={!visible ? setVisible : undefined}
    >
      <FlexRow
        onClick={visible ? setVisible : undefined}
        style={{ cursor: "pointer" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <span>Splits & Pivots</span>
          <i style={{ fontSize: "1.2em" }} className="bi-person-lines-fill"></i>
          {getPivotCount() > 0 && (
            <HorizontalBar rotate height={15} width={2}></HorizontalBar>
          )}
          {getPivotCount() > 0 && (
            <div className={styles.number}>{getPivotCount()}</div>
          )}
        </div>

        <span className={styles.accordionicon}>
          <i className="bi bi-caret-left-fill"></i>
        </span>
      </FlexRow>

      {visible && (
        <div
          className={styles.body}
          style={{ borderRadius: "1em", paddingTop: "10px" }}
        >
          <TablePivots
            viz={viz}
            updateViz={updateViz}
            changeSettingsField={changeSettingsField}
            projects={projects}
            custom_fields={custom_fields}
          />
        </div>
      )}
    </div>
  );
}

export function TablePivots({
  viz,
  updateViz,
  changeSettingsField,
  projects,
  custom_fields,
}) {
  const vizQs = combinedQs(viz);

  function allScale() {
    for (let q of vizQs) {
      if (!q.scaleQuestion) {
        return false;
      }
    }
    return true;
  }

  console.log("in here");

  function hasMatrix() {
    for (let q of vizQs) {
      if (q.matrixQuestion) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      {hasMatrix() && (
        <>
          <MatrixOptions viz={viz} updateViz={updateViz} />
        </>
      )}

      {!hasMatrix() && (
        <>
          <Pivots
            viz={viz}
            custom_fields={custom_fields}
            updateViz={updateViz}
            projects={projects}
          />

          {viz.pivotString && allScale() && !hasMatrix() && (
            <SplitFields
              viz={viz}
              changeSettingsField={changeSettingsField}
              projects={projects}
              custom_fields={custom_fields}
              updateViz={updateViz}
            />
          )}
        </>
      )}
    </>
  );
}

const MatrixOptions = ({ viz, updateViz }) => {
  const vizQs = combinedQs(viz);

  function allMatrix() {
    for (let q of vizQs) {
      if (!q.matrixQuestion) {
        return false;
      }
    }
    return true;
  }

  function allFreeResponse() {
    for (let q of vizQs) {
      if (!q.textQuestion) {
        return false;
      }
    }
    return true;
  }

  function togglePivotOptions(val) {
    let newViz = { ...viz };
    newViz.designSettings.pivotOptions = val;

    if (val) {
      createPivotColumnsForACombinationOfQs(newViz);
    } else {
      newViz.designSettings.tablePivotFields = {};
      newViz.designSettings.tablePivotOrder = [];
    }

    updateViz(newViz);
  }

  return (
    <>
      {allMatrix() && (
        <>
          <div>
            <Label
              style={{
                fontWeight: "600",
                width: "fit-content",
                paddingLeft: "20px",
                marginTop: "10px",
                fontSize: ".9em",
              }}
              labelIcon={<i className="bi bi-question-square"></i>}
              iconPosition="right"
              tooltipText="Organizes the table according to the field each participant falls under"
            >
              {allFreeResponse() ? "Show Participation by" : "Show Rows by"}
            </Label>

            <div className={styles.pivots}>
              <div className={`${styles.item}`}>
                <Checkbox
                  checked={viz.designSettings.pivotOptions}
                  onChange={(e) => togglePivotOptions(e.target.checked)}
                ></Checkbox>
                <span>Options</span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const Pivots = ({ viz, custom_fields, updateViz, projects }) => {
  const vizQs = combinedQs(viz);

  function allScale() {
    for (let q of vizQs) {
      if (!q.scaleQuestion) {
        return false;
      }
    }
    return true;
  }

  function allChoice() {
    for (let q of vizQs) {
      if (!q.choiceQuestion) {
        return false;
      }
    }
    return true;
  }

  function allFreeResponse() {
    for (let q of vizQs) {
      if (!q.textQuestion) {
        return false;
      }
    }
    return true;
  }

  const changePivot = (newPivot) => {
    let newViz = { ...viz };
    newViz.pivotString = newPivot;

    let settings = newViz.designSettings; //JSON.parse(JSON.stringify(viz.designSettings));
    let oldPivot = viz.pivotString;

    if (newPivot) {
      if (allScale()) {
        if (oldPivot) {
          if (
            !newViz.designSettings.split &&
            (oldPivot === "nps" || newPivot === "nps")
          ) {
            //no split and to or from nps - needs a rebuild

            let creator = new PivotTableFieldsCreator(newViz, projects);
            settings = creator.combineFields().designSettings;
          } else {
            //Your switchig out pivots
            let indexOfOld = settings.tablePivotOrder.indexOf(oldPivot);
            settings.tablePivotOrder.splice(indexOfOld, 1);
            delete settings.tablePivotFields[oldPivot];

            settings.tablePivotFields[newPivot] = {
              name: newPivot === "survey quarter" ? "Quarter" : newPivot,
              show: true,
            };
            settings.tablePivotOrder.unshift(newPivot);
          }
        } else {
          //Switching to a new Pivot
          let creator = new PivotTableFieldsCreator(newViz, projects);
          settings = creator.combineFields().designSettings;
        }
      } else if (allFreeResponse()) {
        settings.tablePivotFields = {
          participationCount: { name: "Participation Count", show: true },
        };
        settings.tablePivotFields[newPivot] = { name: newPivot, show: true };
        settings.tablePivotOrder = [newPivot, "participationCount"];
        settings.answerType = ParticipationCount;
      } else if (allChoice()) {
        settings.tablePivotFields = {
          participationCount: { name: "Participation Count", show: true },
        };
        settings.tablePivotFields[newPivot] = { name: newPivot, show: true };
        settings.tablePivotOrder = [newPivot];

        let choices = [];
        let otherLabel = "";
        for (let q of vizQs) {
          for (let choice of q.choiceQuestion.choices) {
            let lowered = choice.toLowerCase();
            if (!choices.includes(lowered)) {
              choices.push(lowered);
            }
          }
          if (q.choiceQuestion.hasOtherOption && !otherLabel) {
            otherLabel = q.choiceQuestion.otherOptionLabel;
          }
        }

        for (let choice of choices) {
          settings.tablePivotFields[choice] = { name: choice, show: true };
          settings.tablePivotOrder.push(choice);
        }

        if (otherLabel) {
          settings.tablePivotFields.otherOption = {
            name: otherLabel,
            show: true,
          };
          settings.tablePivotOrder.push("otherOption");
        }

        settings.tablePivotOrder.push("participationCount");
      } else {
        // We have a combination
        // copy in TableViz.jsx in reCreatePivot...

        settings.tablePivotFields = {
          participationCount: { name: "Participation Count", show: true },
        };
        settings.tablePivotFields[newPivot] = { name: newPivot, show: true };
        settings.tablePivotOrder = [newPivot];

        let scale = false;
        let nps = false;
        let choice = false;
        for (let q of vizQs) {
          if (q.scaleQuestion) {
            scale = true;
            if (q.scaleQuestion.min === 0 && q.scaleQuestion.max === 10) {
              nps = true;
            }
          } else if (q.choiceQuestion) {
            choice = true;
          }
        }

        if (scale) {
          settings.tablePivotFields.avgScore = {
            name: "Avg Score",
            show: true,
          };
          settings.tablePivotOrder.push("avgScore");

          if (nps) {
            settings.tablePivotFields.nps = { name: "NPS", show: true };
            settings.tablePivotFields.promoters = {
              name: "Promoters",
              show: true,
            };
            settings.tablePivotFields.passives = {
              name: "Passives",
              show: true,
            };
            settings.tablePivotFields.detractors = {
              name: "Detractors",
              show: true,
            };

            settings.tablePivotOrder = [
              ...settings.tablePivotOrder,
              ...["nps", "promoters", "passives", "detractors"],
            ];
          }
        }

        if (choice) {
          let choices = [];
          let otherLabel = "";
          for (let q of vizQs) {
            for (let choice of q.choiceQuestion.choices) {
              let lowered = choice.toLowerCase();
              if (!choices.includes(lowered)) {
                choices.push(lowered);
              }
            }
            if (q.choiceQuestion.hasOtherOption && !otherLabel) {
              otherLabel = q.choiceQuestion.otherOptionLabel;
            }
          }

          for (let choice of choices) {
            settings.tablePivotFields[choice] = { name: choice, show: true };
            settings.tablePivotOrder.push(choice);
          }

          if (otherLabel) {
            settings.tablePivotFields.otherOption = {
              name: otherLabel,
              show: true,
            };
            settings.tablePivotOrder.push("otherOption");
          }
        }

        settings.tablePivotOrder.push("participationCount");
      }

      if (viz.designSettings?.totalRows) {
        settings.tablePivotFields.totalRows = {
          name: "Total",
          show: true,
        };
        settings.tablePivotOrder.push("totalRows");
      }
    } else {
      //clear all out
      settings.answerType = AnswerCount;
      settings.split = "";
      settings.tablePivotOrder = [];
      settings.tablePivotFields = {};
    }

    if (settings?.tableSort) {
      settings.tableSort = [];
    }

    newViz.designSettings = settings;
    updateViz(newViz);
  };

  function couldBeNPS() {
    for (let q of vizQs) {
      if (
        !q.scaleQuestion ||
        q.scaleQuestion.min !== 0 ||
        q.scaleQuestion.max !== 10
      ) {
        return false;
      }
    }
    return true;
  }

  const getOptions = () => {
    let options = [...custom_fields.filter((f) => f.filterable !== false)];

    if (combinedProjIds(viz).length > 1) {
      let surveys = [
        { displayName: "survey", name: "survey", delimiter: "" },
        { displayName: "survey date", name: "survey date" },
        { name: "survey quarter", displayName: "survey quarter" },
      ];
      options = [...options, ...surveys];
    }
    if (viz.tagIdsArray.length > 1) {
      options.push({ displayName: "survey tag", name: "survey tag" });
    }
    options.push({ name: "month taken", displayName: "month taken" });
    options.push({ name: "hour taken", displayName: "hour taken" });
    return options;
  };

  function allFreeResponse() {
    for (let q of vizQs) {
      if (!q.textQuestion) {
        return false;
      }
    }
    return true;
  }

  return (
    <div>
      <Label
        style={{
          fontWeight: "700",
          paddingLeft: "20px",
          fontSize: ".9em",
          width: "fit-content",
        }}
      >
        Pivot by
      </Label>
      <div className={styles.pivots}>
        {" "}
        {getOptions().map((field, ind) => (
          <div
            className={`${styles.item} ${
              field.name === viz.designSettings.split ? styles.disabled : ""
            }`}
            key={ind}
          >
            <Checkbox
              checked={viz.pivotString === field.name}
              onChange={(e) => changePivot(e.target.checked ? field.name : "")}
              disabled={field.name === viz.designSettings.split}
            ></Checkbox>
            <span>{field.displayName}</span>
          </div>
        ))}
        {couldBeNPS() && (
          <div
            className={`${styles.item} ${
              "nps" === viz.designSettings.split ? styles.disabled : ""
            }`}
          >
            <Checkbox
              checked={viz.pivotString === "nps"}
              onChange={(e) => changePivot(e.target.checked ? "nps" : "")}
              disabled={"nps" === viz.designSettings.split}
            ></Checkbox>
            <span>passive, promoter, detractor</span>
          </div>
        )}
      </div>
    </div>
  );
};

function SplitFields({
  viz,
  updateViz,
  changeSettingsField,
  projects,
  custom_fields,
}) {
  const vizQs = combinedQs(viz);

  const getSurveyTags = useFetchSurveyTags({
    tagIds: viz.tagIdsArray,
  });

  function combineFields() {
    let creator = new PivotTableFieldsCreator(viz, projects);
    updateViz(creator.combineFields());
  }

  function splitFields(split) {
    let creator = new PivotTableFieldsCreator(viz, projects, getSurveyTags);
    updateViz(creator.splitFields(split, custom_fields));
  }

  const getSplitFields = () => {
    let splitFields = [
      { name: "question", displayName: "question" },
      ...custom_fields.filter((f) => f.filterable !== false),
    ];

    if (projects.length > 1) {
      splitFields = [
        ...splitFields,
        ...[
          { name: "survey date", displayName: "survey date" },
          { name: "survey quarter", displayName: "survey quarter" },
        ],
      ];
    }

    if (viz.tagIdsArray.length > 1) {
      splitFields.push({ name: "survey tag", displayName: "survey tag" });
    }

    // splitFields.push({ name: "month taken", displayName: "month taken" });
    // Does not split by month taken yet. May need a more dynamic approach to generating the columns for that

    return splitFields;
  };

  function handleSplitCheck(split, checked) {
    if (checked) {
      splitFields(split);
    } else {
      combineFields();
    }
  }

  function couldBeNPS() {
    for (let q of vizQs) {
      if (
        !q.scaleQuestion ||
        q.scaleQuestion.min !== 0 ||
        q.scaleQuestion.max !== 10
      ) {
        return false;
      }
    }
    return true;
  }

  function getAnswerTypes() {
    let options = [
      { label: "Avg Score", value: AvgScore },
      {
        label: "Participation Count",
        value: ParticipationCount,
      },
    ];

    if (couldBeNPS()) {
      options.unshift({
        label: "NPS",
        value: NpsScore,
      });
    }

    return options;
  }

  return (
    <>
      {viz.designSettings.split && (
        <div
          className={`${styles.answerTypesContainer} ${
            viz.designSettings.split ? styles.optionsActive : ""
          }`}
        >
          <Label
            style={{
              fontWeight: "700",
              paddingLeft: "20px",
              fontSize: ".9em",
            }}
          >
            Calculate
          </Label>
          <div className={styles.answerTypeOptions}>
            {getAnswerTypes().map((option) => (
              <div className={styles.pivotRadio}>
                {option.label}
                <input
                  id={option.label}
                  type="radio"
                  name="answerType"
                  value={option.value}
                  disabled={!viz.designSettings.split}
                  onChange={() =>
                    changeSettingsField("answerType", option.value)
                  }
                  checked={
                    viz.designSettings.split
                      ? viz.designSettings.answerType === option.value
                      : false
                  }
                />
              </div>
            ))}
          </div>
        </div>
      )}

      <div>
        <Label
          style={{
            fontWeight: "700",
            paddingLeft: "20px",
            fontSize: ".9em",
          }}
        >
          Split by
        </Label>

        <div className={styles.pivots}>
          {getSplitFields().map((split, i) => (
            <div
              className={`${styles.item} ${
                split.name === viz.pivotString ? styles.disabled : ""
              }`}
              key={i}
            >
              <Checkbox
                checked={viz.designSettings.split === split.name}
                onChange={(e) => handleSplitCheck(split.name, e.target.checked)}
                disabled={split.name === viz.pivotString}
              ></Checkbox>
              <span>{split.displayName}</span>
            </div>
          ))}
          {couldBeNPS() && (
            <div
              className={`${styles.item} ${
                "nps" === viz.pivotString ? styles.disabled : ""
              }`}
            >
              <Checkbox
                checked={viz.designSettings.split === "nps"}
                onChange={(e) => handleSplitCheck("nps", e.target.checked)}
                disabled={"nps" === viz.pivotString}
              ></Checkbox>
              <span>passive, promoter, detractor</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
