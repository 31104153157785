import React, { useEffect, useRef, useState } from "react";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import styles from "./DownloadView.module.scss";
import Button from "components/Button/Button";
import Filter from "pages/results/Charts/Settings/Filter/Filter.jsx";
import { useGetFilterableAnswers } from "api/resources/projects/answers";
import { BasicTable } from "components/tables/BasicTable/BasicTable";
import { Loading } from "components/Loading/Loading";
import { CSVLink } from "react-csv";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { forEach, getSortedArray } from "assets/functions/ArrayFunctions";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import Spreadsheet from "components/inputs/input_fields/Spreadsheet/Spreadsheet";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { useFetchQuestionsGql } from "api/resources/projects/questions";
import { useFetchRole } from "api/resources/organization/roles";
import { addColIdStrings } from "assets/functions/ObjectFunctions";

function DownloadView({
  show,
  setShow,
  survey,
  custom_fields,
  externalChosenFilter,
}) {
  const getQs = useFetchQuestionsGql(survey.id);
  const getCurrRole = useFetchRole();

  return (
    <ReactModal
      show={show}
      onClose={() => setShow(false)}
      modalStyle={{
        overflow: "hidden",
        height: "100%",
        width: "100%",
        maxWidth: "1400px",
        borderRadius: "1em",
        // padding: "1em",
        paddingTop: ".5em",
        paddingBottom: "3em",
      }}
      dark
    >
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        {(getQs.isLoading || getCurrRole.isLoading) && <Loading />}
        {getQs.isSuccess && getCurrRole.isSuccess && (
          <DownloadTable
            survey={survey}
            questions={getQs.data.QuestionByProjectID}
            custom_fields={custom_fields}
            externalChosenFilter={externalChosenFilter}
            roles={getCurrRole.data.role}
          />
        )}
      </div>
    </ReactModal>
  );
}

function DownloadTable({
  survey,
  questions,
  custom_fields,
  externalChosenFilter,
  roles,
}) {
  const [showFilter, setShowFilter] = useState(false);
  const [data, setData] = useState([]);
  const [selection, setSelection] = useState();
  const [spreadsheet, setSpreadsheet] = useState(false);

  const ref2 = useRef(null);

  const handleClickOutside = (event) => {
    if (ref2.current && !ref2.current.contains(event.target)) {
      setShowFilter(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  function getChosenFilter() {
    if (externalChosenFilter) {
      return typeof externalChosenFilter != "string"
        ? JSON.stringify(externalChosenFilter)
        : externalChosenFilter;
    } else {
      return {};
    }
  }

  const [chosenFilter, setChosenFilter] = useState(getChosenFilter());
  const [headers, setHeaders] = useState(getHeaders());
  const [headersDownload, setHeadersDownload] = useState(getHeadersDownload());
  const [searchSort, setSearchSort] = useState({
    item: "shortTime",
    descend: false,
  });

  const [search, setSearch] = useState("");

  const fetchAnswers = useGetFilterableAnswers(
    questions?.map((q) => q.id),
    typeof chosenFilter === "string"
      ? chosenFilter
      : JSON.stringify(chosenFilter),
    null,
    setData,
    search
  );

  function getHeadersDownload() {
    let tempHeaders = [];

    tempHeaders.push({
      label: "First Name",
      key: "firstName",
      type: "header",
      text: "First Name",
    });
    tempHeaders.push({
      label: "Last Name",
      key: "lastName",
      type: "header",
      text: "Last Name",
    });
    tempHeaders.push({
      label: "Email",
      key: "email",
      type: "header",
      text: "Email",
    });
    tempHeaders.push({
      label: "Survey",
      key: "survey",
      type: "header",
      text: "Survey",
    });
    tempHeaders.push({
      label: "Time taken",
      key: "shortTime",
      type: "header",
      text: "Time taken",
    });

    tempHeaders = [
      ...tempHeaders,
      ...custom_fields
        .filter((f) => f.filterable !== false)
        .map((f) => {
          return {
            label: f.displayName,
            key: f.id,
            type: "header",
            text: f.displayName,
          };
        }),
    ];

    // insert buckets after corresponding q's
    for (let q of questions) {
      if (q.type === "Matrix") {
        for (let option of q.matrixQuestion.options) {
          tempHeaders.push({
            label: q.questionText + ": " + option,
            key: q.id + "_" + option,
          });
        }
      } else {
        tempHeaders.push({
          label: q.questionText,
          key: q.id,
        });
        if (q.textQuestion && q.textQuestion.bucket) {
          for (let b of q.textQuestion.bucket) {
            tempHeaders.push({
              label: b.name,
              key: b.name,
            });
          }
        }
      }
    }

    return tempHeaders;
  }

  function getHeaders() {
    let tempHeaders = [];

    let i = tempHeaders.length;

    i += 1;
    tempHeaders.push({
      id: i,
      index: i,
      name: "Survey",
      accessor: "survey",
      enabled: true,
      sort: false,
      canSort: true,
      cell_style: null,
      width: "150px",
    });

    if (roles.canSeeContactInfo) {
      tempHeaders.push({
        id: i,
        index: i,
        name: "First Name",
        accessor: "firstName",
        enabled: true,
        sort: false,
        canSort: true,
        cell_style: null,
      });
      i += 1;
      tempHeaders.push({
        id: i,
        index: i,
        name: "Last Name",
        accessor: "lastName",
        enabled: true,
        sort: false,
        canSort: true,
        cell_style: null,
      });
      i += 1;
      tempHeaders.push({
        id: i,
        index: i,
        name: "Email",
        accessor: "email",
        enabled: true,
        sort: false,
        canSort: true,
        cell_style: null,
      });
    }

    i += 1;
    tempHeaders.push({
      id: i,
      index: i,
      name: "Time taken",
      accessor: "shortTime",
      enabled: true,
      sort: true,
      canSort: true,
      cell_style: (date) => <div>{date ? getNiceDate(date) : "none"}</div>,
    });

    // insert buckets after corresponding q's
    for (let q of questions) {
      if (q.type === "Matrix") {
        for (let option of q.matrixQuestion.options) {
          tempHeaders.push({
            id: i,
            index: i,
            name: q.questionText + ": " + option,
            accessor: q.id + "_" + option,
            enabled: true,
            sort: false,
            canSort: true,
            cell_style: null,
            width: "200px",
          });
        }
      } else {
        tempHeaders.push({
          id: i,
          index: i,
          name: q.questionText,
          accessor: q.id,
          enabled: true,
          sort: false,
          canSort: true,
          cell_style: null,
          width: "200px",
        });
        if (q.textQuestion && q.textQuestion.bucket) {
          for (let b of q.textQuestion.bucket) {
            tempHeaders.push({
              id: i,
              index: i,
              name: b.name,
              accessor: b.name,
              enabled: true,
              sort: false,
              canSort: true,
              cell_style: null,
              width: "200px",
            });
          }
        }
      }
    }

    if (roles.canSeeContactInfo) {
      tempHeaders = [
        ...tempHeaders,
        ...custom_fields
          .filter((f) => f.filterable !== false)
          .map((f) => {
            i += 1;
            return {
              id: i,
              index: i,
              name: f.displayName,
              accessor: f.id,
              enabled: true,
              sort: false,
              canSort: true,
              cell_style: null,
              width: "150px",
            };
          }),
      ];
    }

    return tempHeaders;
  }

  useEffect(() => {
    setChosenFilter(getChosenFilter());
  }, [externalChosenFilter]);

  const months = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "June",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec",
  };

  function getNiceDate(when) {
    let date = months[when.getMonth()] + " " + when.getDate() + ", ";
    let hour = when.getHours();
    let am = true;
    if (hour == 0) {
      hour = 12;
    } else if (hour == 12) {
      am = false;
    } else if (hour > 12) {
      am = false;
      hour = hour % 12;
    }
    let min = when.getMinutes();
    if (min < 10) {
      min = "0" + min;
    }
    date += hour + ":" + min + " " + (am ? "am" : "pm");
    return date;
  }

  function getData() {
    if (data) {
      return data;
    } else {
      //query might change to not have to be at the first index
      let answers = [];
      if (
        fetchAnswers.data?.answers &&
        fetchAnswers.data?.answers?.length > 0
      ) {
        answers = fetchAnswers?.data?.answers;
      }
      let finalResults = {};
      if (answers) {
        forEach(answers, (answer) => {
          if (!(answer?.participation?.id in finalResults)) {
            let fields = answer?.participation?.contact
              ? addColIdStrings(answer.participation.contact)
              : {};
            let temp = {
              ...fields,
              firstName: answer?.participation?.contact?.firstName,
              lastName: answer?.participation?.contact?.lastName,
              email: answer?.participation?.contact?.email,
            };

            temp.survey = survey.name;
            if (answer?.participation?.startedAt) {
              let when = new Date(answer.participation.startedAt);
              temp.shortTime = when;
              temp.timeTaken = when;
            } else {
              temp.timeTaken = null;
              temp.shortTime = null;
            }

            finalResults[answer?.participation?.id] = temp;
          }

          let id = answer?.participation?.id;
          if (answer.matrixAnswer) {
            let q = questions.find((q) => q.id === answer.questionId);
            let matrixAnswer = JSON.parse(answer.matrixAnswer);

            for (let option in matrixAnswer) {
              finalResults[id][answer?.questionId + "_" + option] =
                getMatrixAnswer(option, matrixAnswer, q);
            }
          } else {
            finalResults[id][answer?.questionId] = getAnswer(answer);
          }

          // Add in buckets
          if (answer.buckets) {
            let buckets = JSON.parse(answer.buckets);
            for (let b in buckets) {
              let string = "";
              for (let prop of getAnswerBucketLabels(buckets[b])) {
                if (string) {
                  string += ", ";
                }
                string += prop;
              }

              finalResults[id][b] = string;
            }
          }
        });
        let arrayResults = Object.keys(finalResults).map(
          (key) => finalResults[key]
        );

        arrayResults = getSortedArray(arrayResults, (a, b) => {
          if (searchSort.descend) {
            if (searchSort.item === "shortTime") {
              return a[searchSort.item]?.getTime() >
                b[searchSort.item]?.getTime()
                ? 1
                : -1;
            }
            if (!isNaN(a[searchSort.item]) && !isNaN(b[searchSort.item])) {
              return parseInt(a[searchSort.item]) > parseInt(b[searchSort.item])
                ? 1
                : -1;
            }
            if (!isNaN(a[searchSort.item]) && isNaN(b[searchSort.item])) {
              return 1;
            }
            if (isNaN(a[searchSort.item]) && !isNaN(b[searchSort.item])) {
              return -1;
            }
            return a[searchSort.item] > b[searchSort.item] ? 1 : -1;
          } else {
            if (searchSort.item === "shortTime") {
              return a[searchSort.item]?.getTime() <
                b[searchSort.item]?.getTime()
                ? 1
                : -1;
            }
            if (!isNaN(a[searchSort.item]) && !isNaN(b[searchSort.item])) {
              return parseInt(a[searchSort.item]) < parseInt(b[searchSort.item])
                ? 1
                : -1;
            }
            if (!isNaN(a[searchSort.item]) && isNaN(b[searchSort.item])) {
              return -1;
            }
            if (isNaN(a[searchSort.item]) && !isNaN(b[searchSort.item])) {
              return 1;
            }
            return a[searchSort.item] < b[searchSort.item] ? 1 : -1;
          }
        });
        setData(arrayResults);
        return arrayResults;
      } else {
        return [];
      }
    }
  }

  useEffect(() => {
    fetchAnswers.refetch();
  }, [searchSort, search]);

  function downloadFiltersSeperately() {
    // debugger;
    let filtersKeys = Object.keys(chosenFilter);
    let filteredData = [];

    for (let key of filtersKeys) {
      let field = chosenFilter[key].name;
      for (let property of chosenFilter[key].properties) {
        let newArray = [];
        for (let answer of data) {
          if (answer[field].includes(property)) {
            newArray.push(answer);
          }
        }
        filteredData.push({ array: newArray, property: property });
      }
    }
    // Loop the array of objects
    var zip = new JSZip();
    let i = 0;
    for (let dataObject of filteredData) {
      let items = dataObject.array;
      let csv = "";
      for (let row = 0; row < items.length; row++) {
        let keysAmount = headersDownload.length;
        let keysCounter = 0;

        // If this is the first row, generate the headings
        if (row === 0) {
          // Loop each property of the object

          for (let key of headersDownload) {
            // This is to not add a comma at the last cell
            // The '\r\n' adds a new line
            csv +=
              `"${key.label}"` + (keysCounter + 1 < keysAmount ? "," : "\r\n");
            keysCounter++;
          }
          keysCounter = 0;
          for (let key of headersDownload) {
            csv +=
              `"${items[row][key.key] ? items[row][key.key] : ""}"` +
              (keysCounter + 1 < keysAmount ? "," : "\r\n");
            keysCounter++;
          }
        } else {
          for (let key of headersDownload) {
            csv +=
              `"${items[row][key.key] ? items[row][key.key] : ""}"` +
              (keysCounter + 1 < keysAmount ? "," : "\r\n");
            keysCounter++;
          }
        }

        keysCounter = 0;
      }

      zip.file(survey.name + "_" + dataObject.property + "_results.csv", csv);
      i += 1;
    }

    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, survey.name + "_filtered_results.zip");
    });
  }

  const [sort, setSort] = useState([{ sort: "up", col: "shortTime" }]);

  function handleSort(col) {
    let sortCopy = [...sort];
    if (sortCopy.length >= 1 && sortCopy[0].col != col) {
      sortCopy = [];
    }
    let index = sortCopy.findIndex((s) => s.col === col);
    if (index >= 0) {
      if (sortCopy[index].sort === "up") {
        sortCopy.splice(index, 1);
        setSearchSort({
          item: "shortTime",
          descend: false,
        });
      } else {
        sortCopy[index].sort = "up";
        setSearchSort({ item: col, descend: false });
      }
    } else {
      sortCopy.push({
        col: col,
        sort: "down",
      });
      setSearchSort({ item: col, descend: true });
    }

    setSort(sortCopy);
  }

  function onSelectionChanged(val) {
    setSelection(val);
  }

  function getAnswerBucketLabels(result) {
    if (Array.isArray(result)) {
      return result;
    } else {
      return [result.overall];
    }
  }

  function isRanking(qId) {
    let q = questions.find((q) => q.id === qId);
    return q.choiceQuestion?.isRanking;
  }

  function getMatrixAnswer(option, matrixAnswer, question) {
    let string = "";

    if (question.choiceQuestion) {
      let choiceAnswer = matrixAnswer[option];
      const ranking = question.choiceQuestion.isRanking;
      for (let i = 0; i < choiceAnswer.length; i++) {
        if (ranking) {
          string += i + 1 + ": ";
        }
        string += choiceAnswer[i];
        if (i < choiceAnswer.length - 1) {
          if (ranking) {
            string += "  ";
          } else {
            string += ", ";
          }
        }
      }
    } else {
      string += matrixAnswer[option];
    }

    return string;
  }

  function getRankingAnswer(answer) {
    let string = "";
    for (let i = 0; i < answer.choiceAnswer.length; i++) {
      if (string) string += " ";
      string += i + 1 + ": ";
      string += answer.choiceAnswer[i];
    }
    return string;
  }

  function getAnswer(answer) {
    if (answer.choiceAnswer) {
      if (isRanking(answer.questionId)) {
        return getRankingAnswer(answer);
      }
      let string = "";
      for (let i = 0; i < answer.choiceAnswer.length; i++) {
        if (string) string += ", ";
        string += answer.choiceAnswer[i];
      }

      return string;
    }
    if (answer.scaleAnswer || answer.scaleAnswer === 0) {
      return answer.scaleAnswer;
    }
    if (answer.textAnswer) {
      let string = answer.textAnswer.replaceAll(`"`, ``);
      string = string.replaceAll(`'`, ``);
      return string;
    }

    return "";
  }

  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.header}>
          Download {fetchAnswers.isSuccess ? getData().length : 0} results for "
          {survey.name}"
        </div>

        <div className={styles.buttons}>
          <div className={styles.togglespread}>
            <span>{spreadsheet ? "Spreadsheet Mode" : "Table Mode"}</span>
            <ToggleSwitch
              startChecked={spreadsheet}
              handleCheck={(val) => setSpreadsheet(val)}
            />
            ƒ
          </div>
          <NavTextField
            value={search}
            setValue={setSearch}
            placeholder="Search Participant Email..."
            className={styles.search}
          ></NavTextField>

          <div
            className={styles.reportSettingsButton}
            onClick={() => setShowFilter(!showFilter)}
          >
            <i className="bi bi-funnel"></i>
          </div>
          {showFilter && (
            <div className={styles.filter} ref={ref2}>
              <Filter
                custom_fields={custom_fields}
                updateChosenFilters={setChosenFilter}
                chosenFilter={chosenFilter ? chosenFilter : {}}
              ></Filter>
            </div>
          )}
          {Object.keys(chosenFilter).length > 0 && (
            <Button height={39} onClick={downloadFiltersSeperately}>
              {window.innerWidth > 600 ? (
                "Download By Filters"
              ) : (
                <i className="bi bi-cloud-download"></i>
              )}
            </Button>
          )}

          <CSVLink
            data={data ? data : []}
            headers={headersDownload}
            //   headers={[]}
            filename={survey.name + ".csv"}
            style={{
              height: "40px",
              //border: "2px solid black",
              alignContent: "center",
              display: "flex",
            }}
          >
            <Button height={39} shadow blue>
              {window.innerWidth > 600 ? (
                "Download Results"
              ) : (
                <i className="bi bi-cloud-download"></i>
              )}
            </Button>
          </CSVLink>
        </div>
      </div>

      {(fetchAnswers.isLoading || fetchAnswers.isRefetching) && (
        <Loading></Loading>
      )}
      {fetchAnswers.isSuccess && !fetchAnswers.isRefetching && (
        <div className={styles.tableContainer}>
          {spreadsheet && selection && (
            <div className={styles.selection}>{selection}</div>
          )}
          {!spreadsheet && (
            <BasicTable
              data={fetchAnswers.isSuccess ? getData() : []}
              initheaders={headers}
              sort={searchSort}
              setSort={setSearchSort}
              onRowClick={undefined}
              headerSize={10}
              tableSize={12}
              padding={2}
              rowNumbers
              id="download"
              moveableColumns
              paginateScroll
            ></BasicTable>
          )}

          {spreadsheet && (
            <Spreadsheet
              dataArray={getData()}
              headers={headers}
              sort={sort}
              handleSort={handleSort}
              onSelectionChanged={onSelectionChanged}
            ></Spreadsheet>
          )}
        </div>
      )}
    </>
  );
}

export default DownloadView;
