/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2024 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

import React, { useState, useEffect, useRef } from "react";
import { Loading } from "components/Loading/Loading";
import styles from "./CustomFieldsEditor.module.scss";
import { useUpdateUserById } from "api/resources/organization/users";
import Button from "components/Button/Button";
import { useFetchCurrOrgsFields } from "api/resources/organization/organization";
import { ErrorPage } from "pages";
import {
  useFetchContact,
  useUpdateContactGql,
} from "api/resources/contacts/contacts";
import EditSingleCustomField from "./EditSingle";
import { trimDate } from "assets/functions/DateFunctions";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";

export default function CustomFieldsEditor({ contact, disabled, setAdd, add }) {
  const orgFields = useFetchCurrOrgsFields();
  const fetchContact = useFetchContact(contact.id);

  return (
    <>
      {(orgFields.isLoading || fetchContact.isLoading) && <Loading />}
      {(orgFields.isError || fetchContact.isError) && (
        <ErrorPage error={orgFields.error} />
      )}
      {orgFields.isSuccess && fetchContact.isSuccess && (
        <>
          <EditFields
            contact={fetchContact.data.contact}
            orgFields={orgFields.data.getCurrOrgFields}
            disabled={disabled}
            refetch={() => {
              orgFields.refetch();
              fetchContact.refetch();
            }}
            setAdd={setAdd}
            add={add}
          />
        </>
      )}
    </>
  );
}

function EditFields({ orgFields, contact, refetch, add, setAdd, disabled }) {
  const initFields = () => {
    let columns = [];

    if (contact.attribute) {
      for (let field of orgFields) {
        let attributes = contact.attribute
          .filter((a) => a.customFieldId === field.id)
          .map((a) => a.name);
        if (attributes.length) {
          columns.push({
            field: field,
            attributes: attributes,
          });
        }
      }
    }
    return columns;
  };

  const [columns, setColumns] = useState(initFields());
  const [nameAndEmail, setNameAndEmail] = useState({
    firstName: contact.firstName,
    lastName: contact.lastName,
    email: contact.email,
  });
  const [changed, setChanged] = useState(false);
  const [saveWords, setSaveWords] = useState(`Save Contact Fields`);
  const [createNew, setCreateNew] = useState(false);

  function addField(field) {
    let copy = [...columns];
    copy.push({
      field: field,
      attributes: [],
    });

    setColumns(copy);
    setAdd(false);
    setChanged(true);
  }

  function getLeft() {
    let left = [];
    if (orgFields) {
      for (let one of orgFields) {
        if (!(one.name in userFields)) {
          let caps =
            one.displayName.charAt(0).toUpperCase() + one.displayName.slice(1);
          left.push({ name: one.name, displayName: caps, salesforceColumn: one?.salesforceColumn });
        }
      }
    }
    return left;
  }

  const updateUser = useUpdateUserById();
  const updateContact = useUpdateContactGql();

  function saveUserFields() {
    setSaveWords(
      <>
        Saving <Loading width={20} height={20}></Loading>
      </>
    );

    let attributeData = [];
    for (let col of columns) {
      for (let att of col.attributes) {
        attributeData.push({
          customFieldId: col.field.id,
          name: att,
        });
      }
    }

    updateContact.mutate(
      {
        data: {
          firstName: nameAndEmail.firstName,
          lastName: nameAndEmail.lastName,
          email: nameAndEmail.email,
        },
        id: contact.id,
        attributeData: {
          attributes: attributeData,
        },
      },
      {
        onSuccess: (data) => {
          onSaved();
          // setColumns(finalFields ? finalFields : {});
        },
      }
    );
  }

  function onSaved() {
    setSaveWords(
      <>
        Saved <i className="bi bi-check"></i>
      </>
    );
    setTimeout(() => {
      setChanged(false);
      setSaveWords(`Save Contact Fields`);
    }, 1000);
    if (refetch) {
      refetch();
    }
  }

  function changeField(ind, options) {
    let copy = [...columns];
    let column = copy[ind];

    if (column.field.delimiter) {
      column.attributes = options;
    } else {
      column.attributes = [
        typeof options === "string" ? options : options.value,
      ];
    }

    setColumns(copy);
    setChanged(true);
  }

  function cancel() {
    setColumns(initFields());
    setChanged(false);
  }

  function handleNewField(field) {
    addField(field);
    setCreateNew(false);
    refetch();
  }

  return (
    <>
      {" "}
      {add && (
        <AddField
          onClickOut={() => setAdd(false)}
          onAdd={addField}
          options={orgFields.filter(
            (c) => !columns.some((col) => col.field.id === c.id)
          )}
          onCreateNew={() => {
            setCreateNew(true);
            setAdd(false);
          }}
        />
      )}
      <div className={styles.userFields}>
        <div className={styles.userFieldsTable}>
          <div className={styles.customField}>
            <CombinedInput
              label="First Name"
              value={contact.firstName}
              onChange={(val) => {
                setNameAndEmail({ ...nameAndEmail, firstName: val });
                setChanged(true);
              }}
              disable={disabled}
              style={{ backgroundColor: "white" }}
              shadow
              icon={contact?.salesforceId ? "bi-cloud" : ""}
            ></CombinedInput>
          </div>
          <div className={styles.customField}>
            <CombinedInput
              label="Last Name"
              value={contact.lastName}
              onChange={(val) => {
                setNameAndEmail({ ...nameAndEmail, lastName: val });
                setChanged(true);
              }}
              disable={disabled}
              style={{ backgroundColor: "white" }}
              shadow
              icon={contact?.salesforceId ? "bi-cloud" : ""}
            ></CombinedInput>
          </div>
          <div className={styles.customField}>
            <CombinedInput
              label="Email"
              value={contact.email}
              onChange={(val) => {
                setNameAndEmail({ ...nameAndEmail, email: val });
                setChanged(true);
              }}
              disable={disabled}
              style={{ backgroundColor: "white" }}
              shadow
              icon={contact?.salesforceId ? "bi-cloud" : ""}
            ></CombinedInput>
          </div>

          {columns.map((column, i) => {
            const multiple = column.field.delimiter ? true : false;
            const value = multiple ? column.attributes : column.attributes[0];
            return (
              <div className={styles.customField} key={i}>
                <CombinedInput
                  label={column.field.name}
                  value={value}
                  onChange={(val) => changeField(i, val)}
                  options={column.field.properties}
                  selectMultiple={multiple}
                  select={!multiple}
                  search
                  disable={disabled}
                  style={{ backgroundColor: "white" }}
                  shadow
                ></CombinedInput>
              </div>
            );
          })}

          {contact.createdAt && (
            <div className={styles.customField}>
              <CombinedInput
                label={"Created Date"}
                value={trimDate(contact.createdAt, true)}
                disable
                style={{ backgroundColor: "white" }}
                shadow
              ></CombinedInput>
            </div>
          )}
        </div>

        {!add && changed && !disabled && (
          <div
            style={{
              display: "flex",
              gap: "15px",
              height: "30px",
              marginTop: "20px",
              position: "sticky",
              bottom: "2em",
            }}
          >
            <Button seafoam shadow onClick={cancel}>
              <div>Cancel</div>
            </Button>
            <Button shadow onClick={saveUserFields}>
              {saveWords}
            </Button>
          </div>
        )}
        {createNew && (
          <EditSingleCustomField
            brandNew
            onClose={() => setCreateNew(false)}
            onSaved={handleNewField}
          />
        )}
      </div>
    </>
  );
}

export function AddField({ onClickOut, onAdd, options, onCreateNew }) {
  function clickOutside(e) {
    if (ref.current && !ref.current?.contains(e.target)) {
      onClickOut(e);
      document.removeEventListener("click", clickOutside, true);
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  const ref = useRef();

  const onClick = (name) => {
    onAdd(name);
  };

  return (
    <div ref={ref} className={styles.customDropDown}>
      {options.map((field, i) => (
        <div key={i} className={styles.choice} onClick={() => onClick(field)}>
          {field?.displayName ? field.displayName : field.name}
        </div>
      ))}
      <div
        className={`${styles.choice} ${styles.addNew}`}
        onClick={onCreateNew}
      >
        + New Field
      </div>
    </div>
  );
}
