import { forEach } from "./ArrayFunctions";

/**
 *
 * @param {*} obj the object to traverse
 * @param {*} whatToDo a callback function which accepts two parameters, key and value.
 */
export function searchObject(obj, whatToDo) {
  for (let key in obj) {
    whatToDo(key, obj[key]);
  }
}

function sortIntoColumnsByName(attributes, customFields) {
  let columns = {};
  for (let att of attributes) {
    if (columns[att.customFieldId]) {
      columns[att.customFieldId].push(att.name);
    } else {
      columns[att.customFieldId] = [att.name];
    }
  }

  for (let field of customFields) {
    if (field.id in columns) {
      columns[field.name] = columns[field.id];
      delete columns[field.id];
    }
  }

  return columns;
}

export function addColAttributes(contact, customFields) {
  if (contact && contact.attribute) {
    let sorted = sortIntoColumnsByName(contact.attribute, customFields);
    for (let key in sorted) {
      contact[key] = sorted[key];
    }
  }

  return contact;
}

export function addColumnStrings(contact, customFields) {
  if (contact && contact.attribute) {
    let sorted = sortIntoColumnsByName(contact.attribute, customFields);
    for (let key in sorted) {
      let values = sorted[key];
      let string = "";
      for (let val of values) {
        if (string) {
          string += ", ";
        }
        string += val;
      }
      contact[key] = string;
    }
  }

  return contact;
}

function sortIntoColumns(attributes) {
  let columns = {};
  for (let att of attributes) {
    if (columns[att.customFieldId]) {
      columns[att.customFieldId].push(att.name);
    } else {
      columns[att.customFieldId] = [att.name];
    }
  }
  return columns;
}

export function addColIdStrings(contact) {
  if (contact && contact.attribute) {
    let sorted = sortIntoColumns(contact.attribute);
    for (let key in sorted) {
      let values = sorted[key];
      let string = "";
      for (let val of values) {
        if (string) {
          string += ", ";
        }
        string += val;
      }
      contact[key] = string;
    }
  }

  return contact;
}

/**
 *
 * @param {*} contact the contact that needs its fields parsed out
 */
export function parseCustomFields(contactArray) {
  if (contactArray && contactArray.length > 0) {
    let temp = [...contactArray];
    for (let i = 0; i < temp.length; i++) {
      temp[i] = addColIdStrings(temp[i]);
    }
    return temp;
  } else {
    return contactArray;
  }
}

export function lowerize(obj) {
  if (obj) {
    let newobj = {};
    Object.keys(obj).map((key) => {
      if (
        key.toLowerCase().includes("firstname") ||
        key.toLowerCase().includes("lastname")
      ) {
        let item = key[0].toLowerCase() + key.slice(1);
        newobj[item] = obj[key];
      } else {
        let item = key.toLowerCase();
        newobj[item] = obj[key];
      }
    });
    return newobj;
  } else {
    return obj;
  }
}
