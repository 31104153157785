export function trimDate(date, giveTime) {
  if (date) {
    let d = new Date(date);
    let niceString = d.toDateString();
    let month = niceString.substring(4, 7);
    let day = niceString.substring(8, 10);
    day.charAt(0) === "0" ? (day = day.charAt(1)) : null;
    let year = niceString.substring(11);

    let string = month + " " + day + ", " + year;
    if (!giveTime) {
      return string;
    } else {
      let hours = d.getHours();
      let dayTime = "pm";
      if (hours > 0 && hours < 12) {
        hours = hours;
        dayTime = "am";
      } else if (hours > 12) {
        hours = hours - 12;
      } else if (hours === 0) {
        hours = 12;
        dayTime = "am";
      }

      return (
        string +
        " at " +
        hours +
        ":" +
        `${d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes()} ` +
        dayTime
      );
    }
  }
  return undefined;
}

export function trimTime(date) {
  if (date) {
    let d = new Date(date);
    let niceString = d.toDateString();
    let day = niceString.substring(8, 10);
    day.charAt(0) === "0" ? (day = day.charAt(1)) : null;

    let hours = d.getHours();
    let dayTime = "pm";
    if (hours > 0 && hours < 12) {
      hours = hours;
      dayTime = "am";
    } else if (hours > 12) {
      hours = hours - 12;
    } else if (hours === 0) {
      hours = 12;
      dayTime = "am";
    }

    return (
      hours +
      ":" +
      `${d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes()} ` +
      dayTime
    );
  }
}

const days = {
  0: "Sun",
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
};

export function trimTimeDay(date) {
  if (date) {
    let d = new Date(date);
    let niceString = d.toDateString();
    let month = niceString.substring(4, 7);
    let day = niceString.substring(8, 10);
    day.charAt(0) === "0" ? (day = day.charAt(1)) : null;

    let hours = d.getHours();
    let dayTime = "pm";
    if (hours > 0 && hours < 12) {
      hours = hours;
      dayTime = "am";
    } else if (hours > 12) {
      hours = hours - 12;
    } else if (hours === 0) {
      hours = 12;
      dayTime = "am";
    }

    let dayvar = days[date.getDay()];
    return (
      dayvar +
      " " +
      month +
      " " +
      day +
      ", " +
      hours +
      ":" +
      `${d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes()} ` +
      dayTime
    );
  }
}

export function findDay(dayNumber) {
  if (dayNumber == 0) {
    return "Sun";
  } else if (dayNumber == 1) {
    return "Mon";
  } else if (dayNumber == 2) {
    return "Tue";
  } else if (dayNumber == 3) {
    return "Wed";
  } else if (dayNumber == 4) {
    return "Thur";
  } else if (dayNumber == 5) {
    return "Fri";
  } else {
    return "Sat";
  }
}

export function findMonth(dayNumber) {
  if (dayNumber == 0) {
    return "Jan";
  } else if (dayNumber == 1) {
    return "Feb";
  } else if (dayNumber == 2) {
    return "Mar";
  } else if (dayNumber == 3) {
    return "Apr";
  } else if (dayNumber == 4) {
    return "May";
  } else if (dayNumber == 5) {
    return "Jun";
  } else if (dayNumber == 6) {
    return "Jul";
  } else if (dayNumber == 7) {
    return "Aug";
  } else if (dayNumber == 8) {
    return "Sep";
  } else if (dayNumber == 9) {
    return "Oct";
  } else if (dayNumber == 10) {
    return "Nov";
  } else {
    return "Dec";
  }
}

export function findFullMonth(dayNumber) {
  if (dayNumber == 0) {
    return "January";
  } else if (dayNumber == 1) {
    return "February";
  } else if (dayNumber == 2) {
    return "March";
  } else if (dayNumber == 3) {
    return "April";
  } else if (dayNumber == 4) {
    return "May";
  } else if (dayNumber == 5) {
    return "June";
  } else if (dayNumber == 6) {
    return "July";
  } else if (dayNumber == 7) {
    return "August";
  } else if (dayNumber == 8) {
    return "September";
  } else if (dayNumber == 9) {
    return "October";
  } else if (dayNumber == 10) {
    return "November";
  } else {
    return "December";
  }
}