import { SelectFieldCalendar } from "components/inputs/input_fields/SelectFieldCalendar/SelectFieldCalendar";
import React, { useState } from "react";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import Button from "components/Button/Button";
import { trimDate } from "assets/functions/DateFunctions";
import { Label } from "components/layouts/Label/Label";
import styles from "./Scheduler.module.scss";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import Accordion from "components/Accordion/Accordion";
import { maintimezones } from "assets/functions/Variables";

function Scheduler({
  showSchedule,
  setShowSchedule,
  currEmail,
  setValidateSend,
  handleScheduleChange,
  validateSend,
  nextText,
  onClose,
  audiences,
  audienceSchedule,
  setAudienceSchedule,
  byAudience,
  setByAudience,
  timezone,
  setTimezone
}) {
  function scheduleChange(val, id) {
    if (byAudience) {
      let temp = { ...audienceSchedule };
      temp[id].date = val;
      setAudienceSchedule(temp);
    } else {
      handleScheduleChange(val);
    }
  }

  function timeZoneChange(val, id) {
    if (byAudience) {
      let temp = { ...audienceSchedule };
      temp[id].timezone = val;
      setAudienceSchedule(temp);
    } else {
      handleScheduleChange(val);
    }
  }

  return (
    <ReactModal
      show={showSchedule && !validateSend}
      onClose={() => {
        onClose ? onClose() : setShowSchedule(false);
      }}
      modalStyle={{
        borderRadius: "1em",
        // height: "100%",
        maxWidth: "800px",
        width: "100%",
        padding: "0em 1em 1em",
        gap: "1em",
        overflow: byAudience ? "auto" : "visible",
      }}
      dark
    >
      <>
        <div className={styles.topHeader}>
          <Label
            style={{paddingLeft: '0px'}}
            // tooltipText={
            //   "Reminder: There is a time delay between emails. This date and time you have chosen is when the first email will be sent out, the rest of the emails will follow with a time delay minimum of 1 second."
            // }
            // labelIcon={<i className="bi bi-question-circle"></i>}
          >
            {currEmail.status === "Scheduled"
              ? "Email Is Scheduled for " + trimDate(currEmail.scheduledDate)
              : `When would you like to start sending "${currEmail.name}"?`}
          </Label>
        </div>
        {/* {audiences && audiences.length > 1 && (
          <div className={styles.switch}>
            <ToggleSwitch
              startChecked={byAudience}
              handleCheck={(val) => setByAudience(val)}
            />
            By Audience
          </div>
        )} */}
        {!byAudience && (
          <SelectFieldCalendar
            value={
              currEmail.scheduledDate ? currEmail.scheduledDate : new Date()
            }
            startDate={
              currEmail.scheduledDate ? currEmail.scheduledDate : new Date()
            }
            onChange={handleScheduleChange}
            disabled={currEmail.status === "Scheduled"}
            timeZone={timezone}
            onChangeTimeZone={setTimezone}
            blockView
            finalDate
          ></SelectFieldCalendar>
        )}
        {byAudience && (
          <div className={styles.accordion}>
            {Object.keys(audienceSchedule).map((id) => (
              <Accordion
                disableCheckbox
                name={
                  <div className={styles.audHeader}>
                    {audienceSchedule[id]?.name}{" "}
                    <a>
                      {trimDate(audienceSchedule[id]?.date, true)}
                      <div>
                        (
                        {
                          maintimezones.find(
                            (t) => t.value === audienceSchedule[id].timezone
                          )?.name
                        }
                        )
                      </div>
                    </a>
                  </div>
                }
                key={id}
              >
                <div style={{ width: "100%" }}>
                  <SelectFieldCalendar
                    value={audienceSchedule[id].date}
                    startDate={audienceSchedule[id].date}
                    onChange={(val) => scheduleChange(val.fullText, id)}
                    timeZone={audienceSchedule[id]?.timezone}
                    onChangeTimeZone={(val) => timeZoneChange(val.value, id)}
                    disabled={currEmail.status === "Scheduled"}
                    blockView
                  ></SelectFieldCalendar>
                </div>
              </Accordion>
            ))}
          </div>
        )}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "2em",
            position: "sticky",
            bottom: "1em",
            gap: "1em",
          }}
        >
          <Button
            shadow
            red
            onClick={() => {
              onClose ? onClose() : setShowSchedule(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={() => setValidateSend(true)} height={35} shadow>
            {nextText ? nextText : "Next"}
          </Button>
        </div>{" "}
      </>
    </ReactModal>
  );
}

export default Scheduler;
