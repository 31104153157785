/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

// External
import { useQueryClient } from "react-query";
import { gql } from "graphql-request";

// Internal
import { useGqlMutation, useGqlQuery } from "api/Api";

// GRAPHQL API

export const useFetchMetrics = (data, id) => {
  const query = gql`
    query ($query: MetricQuery!) {
      getMetrics(query: $query) {
        audienceSize
        avgSurveyTime
        needsAddressing
        npsScore
        numberOfComments
        responseCount
        responseRate
        projectIds
      }
    }
  `;

  return useGqlQuery(["metrics" + id + JSON.stringify(data)], query, {
    query: data,
  });
};

export const useFetchWidgetsOfType = (type, dashId) => {
  const query = gql`
    query {
      getWidgetsOfType(type: "${type}", dashId: "${dashId}") {
        dashLayouts
        widgets {
          id
          index
          name
          settings
          type
        }
      }
    }
  `;

  return useGqlQuery(["widgetsOfType " + type, "from " + dashId], query);
};

export const useCheckQMetricOptions = (data) => {
  const query = gql`
    query CheckForQOptions($query: SurveyMetricData!) {
      checkForQOptions(query: $query) {
        comments {
          id
          questionText
          projectId
        }
        nps {
          id
          questionText
          projectId
        }
        flywheel {
          id
          questionText
          projectId
        }
      }
    }
  `;

  return useGqlQuery(["checkQOptions: " + JSON.stringify(data)], query, {
    query: data,
  });
};

export const useGetOldQMetric = (data) => {
  const query = gql`
    query Query($query: QMetricQuery!) {
      getOldQMetric(query: $query)
    }
  `;

  return useGqlQuery(["QMetric" + JSON.stringify(data)], query, {
    query: data,
  });
};

export const useCreateWidget = () => {
  const mutation = gql`
    mutation CreateWidget($layouts: String!, $data: WidgetInput!) {
      createWidget(layouts: $layouts, data: $data) {
        id
      }
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("dashboards");
      queryClient.invalidateQueries("widgetsOfType charts");
      queryClient.invalidateQueries("widgetsOfType reports");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useDeleteWidget = () => {
  const mutation = gql`
    mutation DeleteWidget($id: String!) {
      deleteWidget(id: $id)
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("dashboards");
      queryClient.invalidateQueries("widgetsOfType charts");
      queryClient.invalidateQueries("widgetsOfType reports");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useAddChartToWidgets = () => {
  const mutation = gql`
    mutation Mutation($widgetIdArray: WidgetIdArray!, $vizId: String!) {
      success: addChartToTheseWidgets(
        widgetIdArray: $widgetIdArray
        vizId: $vizId
      )
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("dashboards");
      queryClient.invalidateQueries("widgetsOfType charts");
    },
  };

  return useGqlMutation(mutation, options);
};
